<template>
  <div class="mb-3">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="4"></v-col>
      <v-col md="4" class="d-flex justify-center">
        <span class="text-h4 font-weight-bold secondary--text"
          >Complete your Profile</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="hidden-sm-and-down" cols="4"></v-col>
      <v-col md="4" class="px-12">
        <v-card color="primary">
          <v-container class="pt-6">
            <v-form v-model="validForm">
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8">
                  <v-text-field
                    label="First Name"
                    color="secondary"
                    v-model="firstName"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8">
                  <v-text-field
                    label="Last Name"
                    color="secondary"
                    v-model="lastName"
                    :rules="[notEmpty]"
                    @keyup.enter="saveProfile"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  color="secondary"
                  :disabled="!validForm"
                  @click="saveProfile"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="hidden-md-and-down" cols="3"></v-col>
      <v-col lg="6">
        <v-card color="primary">
          <v-container class="px-16">
            <v-row>
              <v-col class="d-flex justify-center">
                <span class="text-h6 secondary--text font-weight-bold"
                  >BETA Disclaimer</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{ this.$applicationName }} is currently in a
                <span class="font-weight-bold">BETA-State</span>. All functions
                haven't gone through any Stress-Test so far. Please keep that in
                mind while using the service!<br />If any irregularities or bugs
                emerge on any functionalities
                {{ this.$applicationName }} provides, please report them as
                detailed as possible via the
                <router-link to="reportbug" class="secondary--text"
                  >Bug-Form</router-link
                >. Marked in the Navigation Bar with
                <v-icon color="secondary">mdi-bug</v-icon>!<br />Please submit
                any Ideas or Wishes about possible future Features regarding
                {{ this.$applicationName }} in the exact same Form too.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Thank you for testing {{ this.$applicationName }} and helping me
                improving it step by step!
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="font-weight-bold">Happy Tracking!</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Philipp</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "FirstLogin",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "First Login",
    meta: [
      {
        name: "description",
        content:
          "Finishing profile setup by submitting extra profile data on first login",
      },
    ],
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      validForm: false,
    };
  },
  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
  },
  methods: {
    saveProfile: async function () {
      const user = supabase.auth.user();

      const { data } = await supabase
        .from("profiles")
        .update({
          name: this.firstName,
          last_name: this.lastName,
          display_name: this.firstName + " " + this.lastName,
          provider: user.app_metadata.provider,
          updated: Date.now(),
          created: Date.now(),
        })
        .match({ id: user.id });

      if (data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Sign-Up",
          message: "Successfully saved your Profile! You're all set now!",
        };
        this.$globalState.addNotification(notify);
        this.$router.push({ path: "/tracker" });
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Sign-Up",
          message: "Failed saving your Profile! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
  },
};
</script>
<style scoped></style>
