var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('heading',{attrs:{"text":"Your Favourite Gas Stations"}}),_c('v-row',[_c('v-col',{staticClass:"hidden-md-and-down",attrs:{"cols":"2"}}),_c('v-col',{attrs:{"lg":"8"}},[_c('v-card',{attrs:{"color":"primary","elevation":"10"}},[_c('div',[_c('v-row',{staticClass:"px-4"},[_c('v-col',[_c('span',{staticClass:"text-h5 font-weight-bold secondary--text"},[_vm._v("Station List")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getStations}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-row',{staticClass:"px-4 mt-n8"},[_c('v-col',[_c('span',{staticClass:"text-caption"},[_vm._v("Click on a table row for a detailed view of the selected Station")])])],1)],1),_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.stations.headers,"items":_vm.stations.items,"disable-pagination":"","hide-default-footer":"","loading":_vm.stations.loading,"no-data-text":"You haven't marked any Gas Stations as your Favourite so far","custom-sort":_vm.customSort},on:{"click:row":_vm.handleDetailView},scopedSlots:_vm._u([{key:"item.isOpen",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[(item.isOpen)?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-clock-check-outline")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-clock-remove-outline")])],1)]}},{key:"item.dist",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.dist)+" km ")])]}},{key:"item.e5",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"secondary--text font-weight-bold"},[_vm._v(" "+_vm._s(item.e5 || " - ")+" ")])]}},{key:"item.e10",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"secondary--text font-weight-bold"},[_vm._v(" "+_vm._s(item.e10 || " - ")+" ")])]}},{key:"item.diesel",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"secondary--text font-weight-bold"},[_vm._v(" "+_vm._s(item.diesel || " - ")+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }