<template>
  <div>
    <v-row>
      <v-col class="hidden-md-and-down" cols="2"></v-col>
      <v-col lg="8">
        <h1 class="secondary--text">Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Philipp Katzenberger<br /></p>

        <h2>Kontakt</h2>
        <p>
          E-Mail:
          <a class="secondary--text" href="mailto:contact@fuel-tracker.com"
            >contact@fuel-tracker.com</a
          >
        </p>

        <p>
          Quelle:
          <a class="secondary--text" href="https://www.e-recht24.de"
            >e-recht24.de</a
          >
        </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Imprint",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Imprint (DE)",
      meta: [
        {
          name: "description",
          content: "Imprint of " + this.$applicationName,
        },
      ],
    };
  },
};
</script>
<style scoped></style>
