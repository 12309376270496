function createDate(epoch) {
  let date = new Date(epoch);

  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  let hours = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  let seconds = ("0" + (date.getSeconds() + 1)).slice(-2);

  let timestamp =
    day +
    "." +
    month +
    "." +
    year +
    " - " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;

  return timestamp;
}

export { createDate };
