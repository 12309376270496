<template>
  <v-card color="primary" height="100%" :elevation="elevation">
    <v-container>
      <v-row v-if="cardTitle">
        <v-col>
          <span
            class="text-md-h6 text-subtitle-1 font-weight-bold secondary--text"
            >{{ cardTitle }}
          </span>
        </v-col>
        <v-col cols="1" class="d-flex justify-end">
          <v-btn
            color="secondary"
            :disabled="!refuelAction.valid"
            @click="addRefuelAction"
          >
            <v-icon color="primary"> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="refuelAction.valid" ref="refuelForm">
        <v-row>
          <v-col>
            <v-text-field
              color="secondary"
              label="New Mileage"
              v-model="refuelAction.action.newMileage"
              :rules="[notEmpty, higherThanCurrentMileage]"
              type="number"
              :placeholder="selectedCar.mileage + ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              color="secondary"
              label="Fueled Litres"
              type="number"
              v-model="refuelAction.action.litres"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="gasStations"
              color="secondary"
              item-color="secondary"
              item-text="displayName"
              label="Gas Station"
              return-object
              v-model="refuelAction.action.gasStation"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              color="secondary"
              label="Overall Price"
              type="number"
              v-model="refuelAction.action.price"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              color="secondary"
              label="Filling State"
              item-color="secondary"
              item-text="displayText"
              :items="stashAction.items"
              v-model="stashAction.selectedItem"
              :rules="[itemSelected]"
              return-object
              @keyup.enter="addRefuelAction"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="hidden-md-and-up">
        <v-col class="d-flex justify-center">
          <v-btn
            color="secondary"
            :disabled="!refuelAction.valid"
            @click="addRefuelAction"
          >
            Add Refuel Action
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
// TODO calculate price after litres amount entered
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "refuelCar",
  props: {
    selectedCar: Object,
    refuelActionItems: Array,
    elevation: Number,
    cardTitle: String,
  },
  mounted() {
    this.getStations();
  },
  data() {
    return {
      stashAction: {
        selectedItem: null,
        items: [
          {
            displayText: "I filled my tank completely!",
            completeTank: true,
          },
          {
            displayText: "I didn't fill my tank completely!",
            completeTank: false,
          },
        ],
      },
      refuelAction: {
        valid: false,
        action: {
          newMileage: "",
          litres: "",
          gasStation: "",
          price: "",
        },
      },
      gasStations: [],
      refuelActions: {
        items: [],
      },
    };
  },
  methods: {
    parseFloatGerman: function (string) {
      if (typeof string === "string" && string.includes(",")) {
        let ansiFloat = string.replace(",", ".");
        return parseFloat(ansiFloat);
      }
      return parseFloat(string);
    },
    addRefuelAction: async function () {
      let tripConsumption =
        this.parseFloatGerman(this.refuelAction.action.litres) /
        ((this.parseFloatGerman(this.refuelAction.action.newMileage) -
          this.parseFloatGerman(this.selectedCar.mileage)) /
          100);
      let pricePerLitre =
        this.parseFloatGerman(this.refuelAction.action.price) /
        this.parseFloatGerman(this.refuelAction.action.litres);
      let actionTrip =
        this.refuelAction.action.newMileage - this.selectedCar.mileage;

      let gasStationId = null;

      if (this.refuelAction.action.gasStation) {
        gasStationId = this.refuelAction.action.gasStation.id;
      }

      let mergedStash = false;

      if (this.stashAction.selectedItem.completeTank) {
        //filled tank

        let stashed = await supabase.from("stashedactions").select();

        if (stashed && stashed.data) {
          if (stashed.data.length > 0) {
            //there are stashed actions
            mergedStash = true;
            let tripSum =
              this.parseFloatGerman(this.refuelAction.action.newMileage) -
              this.parseFloatGerman(this.selectedCar.mileage);
            let litreSum = this.parseFloatGerman(
              this.refuelAction.action.litres
            );
            let priceSum = this.parseFloatGerman(
              this.refuelAction.action.price
            );

            let stashedActions = stashed.data;

            for (let i = 0; i < stashedActions.length; i++) {
              tripSum = tripSum + stashedActions[i].trip;
              litreSum = litreSum + stashedActions[i].litres;
              priceSum = priceSum + stashedActions[i].price;
            }
            tripConsumption = this.parseFloatGerman(litreSum) / (tripSum / 100);
            pricePerLitre =
              this.parseFloatGerman(priceSum) / this.parseFloatGerman(litreSum);
            gasStationId = null;
          }
          //no stashed action
          this.refuelActionItems.push({
            trip_consumption: tripConsumption,
          });

          let averageConsumptionSum = 0;
          for (let i = 0; i < this.refuelActionItems.length; i++) {
            averageConsumptionSum =
              averageConsumptionSum +
              this.refuelActionItems[i].trip_consumption;
          }

          let carConsumption =
            averageConsumptionSum / this.refuelActionItems.length;

          let insertObject = {
            user_id: supabase.auth.user().id,
            car_id: this.selectedCar.id,
            trip: actionTrip,
            trip_consumption: tripConsumption,
            car_consumption: carConsumption,
            price_per_litre: pricePerLitre,
            station_id: gasStationId,
            fuel_type: this.selectedCar.fuel_type,
            litres: this.parseFloatGerman(this.refuelAction.action.litres),
            price: this.parseFloatGerman(this.refuelAction.action.price),
            merged_stash: mergedStash,
            created: Date.now(),
          };

          let responseInsert = await supabase
            .from("refuelactions")
            .insert([insertObject]);

          if (responseInsert.error) {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "Adding Refuel-Action",
              message: responseInsert.error,
            };
            this.$globalState.addNotification(notify);
          } else if (responseInsert.data.length > 0) {
            //delete stashed items if there were any
            if (stashed.data.length > 0) {
              let deleteFailed = false;
              for (let i = 0; i < stashed.data.length; i++) {
                let responseDelete = await supabase
                  .from("stashedactions")
                  .delete()
                  .match({ id: stashed.data[i].id });
                if (responseDelete.error) {
                  deleteFailed = true;
                  i = stashed.data.length;
                }
              }

              if (deleteFailed) {
                setTimeout(() => {
                  let notify = {
                    duration: 4000,
                    type: "error",
                    headline: "Deleting stashed Refuel-Actions Failed",
                    message:
                      "Please contact Team before you add next Refuel-Action!",
                  };
                  this.$globalState.addNotification(notify);
                }, 4000);
              }
            }
            let responseUpdate = await supabase
              .from("cars")
              .update({
                mileage: this.refuelAction.action.newMileage.replace(",", "."),
                consumption: carConsumption,
                costs:
                  this.parseFloatGerman(this.selectedCar.costs) +
                  this.parseFloatGerman(this.refuelAction.action.price),
                times_refueled: this.selectedCar.times_refueled + 1,
                updated: Date.now(),
              })
              .match({ id: this.selectedCar.id });

            if (responseUpdate.error) {
              let notify = {
                duration: 4000,
                type: "error",
                headline: "Adding Refuel-Action",
                message: responseUpdate.error,
              };
              this.$globalState.addNotification(notify);
            } else if (responseUpdate.data.length > 0) {
              let notify = {
                duration: 4000,
                type: "success",
                headline: "Adding Refuel-Action",
                message:
                  "Successfully added Refuel-Action to " +
                  this.selectedCar.brand +
                  " " +
                  this.selectedCar.model,
              };
              this.$globalState.addNotification(notify);
              this.$emit("update:selectedCar", responseUpdate.data[0]);
              this.$refs.refuelForm.reset();
            } else {
              let notify = {
                duration: 4000,
                type: "error",
                headline: "Adding Refuel-Action",
                message:
                  "Failed updating Car-Stats! Please reach out to us to fix this Issue!",
              };
              this.$globalState.addNotification(notify);
            }
          } else {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "Adding Refuel-Action",
              message: "Failed adding Refuel-Action! Please Try again later!",
            };
            this.$globalState.addNotification(notify);
          }
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Adding Refuel-Action",
            message: "Failed adding Refuel-Action! Please try again later!",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        //partly filled
        let stash = {
          user_id: supabase.auth.user().id,
          car_id: this.selectedCar.id,
          trip: actionTrip,
          trip_consumption: tripConsumption,
          price_per_litre: pricePerLitre,
          station_id: gasStationId,
          fuel_type: this.selectedCar.fuel_type,
          litres: this.parseFloatGerman(this.refuelAction.action.litres),
          price: this.parseFloatGerman(this.refuelAction.action.price),
          created: Date.now(),
        };

        let responseInsert = await supabase
          .from("stashedactions")
          .insert([stash]);

        if (responseInsert.error) {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Stashing Refuel-Action",
            message: responseInsert.error,
          };
          this.$globalState.addNotification(notify);
        } else if (responseInsert.data.length > 0) {
          let responseUpdate = await supabase
            .from("cars")
            .update({
              mileage: this.refuelAction.action.newMileage.replace(",", "."),
              costs:
                this.parseFloatGerman(this.selectedCar.costs) +
                this.parseFloatGerman(this.refuelAction.action.price),
              times_refueled: this.selectedCar.times_refueled + 1,
              updated: Date.now(),
            })
            .match({ id: this.selectedCar.id });

          if (responseUpdate.error) {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "Stashing Refuel-Action",
              message: responseUpdate.error,
            };
            this.$globalState.addNotification(notify);
          } else if (responseUpdate.data.length > 0) {
            let notify = {
              duration: 4000,
              type: "success",
              headline: "Stashing Refuel-Action",
              message:
                "Successfully stashed Refuel-Action for " +
                this.selectedCar.brand +
                " " +
                this.selectedCar.model,
            };
            this.$globalState.addNotification(notify);
            this.$emit("update:selectedCar", responseUpdate.data[0]);
            this.$refs.refuelForm.reset();
          } else {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "Stashing Refuel-Action",
              message:
                "Failed updating Car-Stats! Please reach out to us to fix this Issue!",
            };
            this.$globalState.addNotification(notify);
          }
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Stashing Refuel-Action",
            message: "Failed stashing Refuel-Action! Please Try again later!",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    getStations: async function () {
      let payload = {
        longitude: this.$globalState.searchParams.location.longitude,
        latitude: this.$globalState.searchParams.location.latitude,
        type: this.selectedCar.fuel_type,
        radius: 10,
      };
      // MOCKUP PAYLOAD
      // let payload = {
      //   longitude: 13.374495,
      //   latitude: 52.528155,
      //   type: this.selectedCar.fuel_type,
      //   radius: 10,
      // };
      let response = await fetch(this.$apiUrl + "/station/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      let responseData = await response.json();

      if (responseData.state) {
        for (let i = 0; i < responseData.stations.length; i++) {
          let station = responseData.stations[i];
          station.displayName =
            responseData.stations[i].brand +
            " - " +
            responseData.stations[i].name;
          this.gasStations.push(station);
        }
        this.gasStations = responseData.stations;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Gas Stations",
          message: responseData.msg,
        };
        this.$globalState.addNotification(notify);
      }
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    itemSelected: function (input) {
      if (input) {
        return true;
      }
      return "One option must be selected!";
    },
    higherThanCurrentMileage: function (input) {
      if (input > this.selectedCar.mileage) {
        return true;
      }
      return "Are you driving a Delorean from Back to the Future? New Mileage must be higher than the current :D";
    },
  },
};
</script>
<style scoped>
.theme--dark.v-list {
  background: var(--v-primary-base);
}
</style>
