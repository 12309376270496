<template>
  <div>
    <v-row v-if="$vuetify.breakpoint.width > 1200" style="height: 93vh">
      <v-col
        v-if="$route.query.redirect === 'Tracker'"
        class="d-flex justify-center align-center"
      >
        <div style="width: 90%">
          <div style="width: 100%" class="text-center pb-10">
            <span class="text-h4 font-weight-bold secondary--text"
              >Try it yourself with this Example</span
            >
            <v-card color="primary" class="mt-4">
              <v-container>
                <v-form v-model="exampleAction.valid">
                  <v-row>
                    <v-col style="width: 20%">
                      <v-text-field
                        color="secondary"
                        label="New Mileage"
                        v-model="exampleAction.action.newMileage"
                        :rules="[notEmpty, higherThanCurrentMileage]"
                        type="number"
                        :placeholder="exampleData.car.mileage + ''"
                      ></v-text-field>
                    </v-col>
                    <v-col style="width: 20%">
                      <v-text-field
                        color="secondary"
                        label="Fueled Litres"
                        type="number"
                        v-model="exampleAction.action.litres"
                        :rules="[notEmpty]"
                      ></v-text-field>
                    </v-col>
                    <v-col style="width: 20%">
                      <v-select
                        :items="gasStations"
                        color="secondary"
                        item-color="secondary"
                        item-text="displayName"
                        label="Gas Station"
                        return-object
                        v-model="exampleAction.action.gasStation"
                      ></v-select>
                    </v-col>
                    <v-col style="width: 20%">
                      <v-text-field
                        color="secondary"
                        label="Overall Price"
                        type="number"
                        v-model="exampleAction.action.price"
                        :rules="[notEmpty]"
                        @keyup.enter="addRefuelAction"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-center align-center">
                      <v-btn
                        color="secondary"
                        :disabled="!exampleAction.valid"
                        @click="addRefuelAction"
                      >
                        Add
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </div>
          <refuelHistory
            v-bind:refuelActionItems.sync="exampleData.items"
            v-bind:selectedCar.sync="exampleData.car"
            :elevation="6"
            :cardTitle="
              'Refuel History for ' + exampleData.car.display_name + ' - '
            "
            noDataMessage="Will be displayed as soon as you have refueled at least one time!"
          />
        </div>
      </v-col>
      <v-col
        v-if="$route.query.redirect === 'Tracker'"
        cols="1"
        class="d-flex justify-center px-0"
      >
        <v-divider vertical></v-divider>
      </v-col>
      <v-col
        :cols="$route.query.redirect === 'Tracker' ? 4 : 12"
        class="d-flex justify-center align-center"
      >
        <authForm />
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-if="$route.query.redirect === 'Tracker'">
        <v-col>
          <v-expansion-panels>
            <v-expansion-panel class="primary">
              <v-expansion-panel-header class="font-weight-bold">
                Try it yourself with this Example
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card color="primary" elevation="0">
                  <v-container>
                    <v-form v-model="exampleAction.valid">
                      <v-row>
                        <v-col>
                          <v-text-field
                            color="secondary"
                            label="New Mileage"
                            v-model="exampleAction.action.newMileage"
                            :rules="[notEmpty, higherThanCurrentMileage]"
                            type="number"
                            :placeholder="exampleData.car.mileage + ''"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            color="secondary"
                            label="Fueled Litres"
                            type="number"
                            v-model="exampleAction.action.litres"
                            :rules="[notEmpty]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="gasStations"
                            color="secondary"
                            item-color="secondary"
                            item-text="displayName"
                            label="Gas Station"
                            return-object
                            v-model="exampleAction.action.gasStation"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            color="secondary"
                            label="Overall Price"
                            type="number"
                            v-model="exampleAction.action.price"
                            :rules="[notEmpty]"
                            @keyup.enter="addRefuelAction"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <v-btn
                          color="secondary"
                          :disabled="!exampleAction.valid"
                          @click="addRefuelAction"
                        >
                          Add
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <refuelHistory
                  class="mt-4"
                  v-bind:refuelActionItems.sync="exampleData.items"
                  v-bind:selectedCar.sync="exampleData.car"
                  :elevation="0"
                  :cardTitle="
                    'Refuel History for ' + exampleData.car.display_name + ' - '
                  "
                  noDataMessage="Will be displayed as soon as you have refueled at least one time!"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row v-if="$route.query.redirect === 'Tracker'">
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row style="height: 80vh">
        <v-col class="d-flex justify-center align-center">
          <authForm />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import authForm from "../components/authForm.vue";
import refuelHistory from "../components/refuelHistory.vue";
export default {
  name: "Login",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Login",
      meta: [
        {
          name: "description",
          content:
            "Login or Sign-Up to " +
            this.$applicationName +
            " and start tracking your Fuel-Statistics",
        },
      ],
    };
  },
  components: {
    authForm,
    refuelHistory,
  },
  mounted() {
    this.getStations();
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
  },
  data() {
    return {
      exampleAction: {
        action: {
          newMileage: "",
          litres: "",
          gasStation: "",
          price: "",
        },
        valid: false,
      },
      exampleData: {
        items: [
          {
            id: "f4f6325d-165f-45ca-ab52-129fe4fdf832",
            user_id: "6d235de5-66f3-44e9-8455-b7d45a04e477",
            car_id: "24aadd76-5a60-4e39-9ee2-856636c0ffa8",
            trip: 132,
            trip_consumption: 9.4,
            car_consumption: 9.4,
            price_per_litre: 1.72,
            station_id: "af8b14d6-0af5-4d86-a2d5-947dc569fd9a",
            fuel_type: "e5",
            litres: 12.41,
            price: 21.35,
            created: 1643573760000,
          },
          {
            id: "8cddcd61-e603-4077-b709-13ef84753259",
            user_id: "6d235de5-66f3-44e9-8455-b7d45a04e477",
            car_id: "24aadd76-5a60-4e39-9ee2-856636c0ffa8",
            trip: 241,
            trip_consumption: 10.7,
            car_consumption: 10.05,
            price_per_litre: 1.75,
            station_id: "439655b5-6dd3-4533-8f47-8fb072a97768",
            fuel_type: "e5",
            litres: 25.79,
            price: 45.13,
            created: 1644264540000,
          },
          {
            id: "8cddcd61-e603-4077-b709-13ef84753252",
            user_id: "6d235de5-66f3-44e9-8455-b7d45a04e477",
            car_id: "24aadd76-5a60-4e39-9ee2-856636c0ffa8",
            trip: 90,
            trip_consumption: 8.6,
            car_consumption: 9.5666667,
            price_per_litre: 1.81,
            station_id: "9d96a1b7-777b-49de-90e2-c5b275c7a359",
            fuel_type: "e5",
            litres: 7.74,
            price: 14.01,
            created: 1644556140000,
          },
          {
            id: "8cddcd61-e603-4077-b709-13ef84753254",
            user_id: "6d235de5-66f3-44e9-8455-b7d45a04e477",
            car_id: "24aadd76-5a60-4e39-9ee2-856636c0ffa8",
            trip: 177,
            trip_consumption: 12.4,
            car_consumption: 10.275,
            price_per_litre: 1.69,
            station_id: "e8c7a351-f2a3-4118-831e-8f23f8778edc",
            fuel_type: "e5",
            litres: 21.95,
            price: 37.1,
            created: 1646165740000,
          },
          {
            id: "8cddcd61-e603-4077-b709-13ef84753256",
            user_id: "6d235de5-66f3-44e9-8455-b7d45a04e477",
            car_id: "24aadd76-5a60-4e39-9ee2-856636c0ffa8",
            trip: 204,
            trip_consumption: 11.5,
            car_consumption: 10.52,
            price_per_litre: 1.73,
            station_id: "4cdb0e9b-1bb9-432d-86b8-ebdb8ee3fcb4",
            fuel_type: "e5",
            litres: 23.46,
            price: 40.59,
            created: 1646324940000,
          },
        ],
        car: {
          display_name: "BMW F32 435i",
          brand: "BMW",
          model: "F32 435i",
          fuel_type: "e5",
          mileage: 32000,
          consumption: 0,
          costs: 0,
          times_refueled: 0,
          created: 1641476930608,
          updated: 1641476930608,
        },
      },
      gasStations: [],
    };
  },
  methods: {
    parseFloatGerman: function (string) {
      if (typeof string === "string" && string.includes(",")) {
        let ansiFloat = string.replace(",", ".");
        return parseFloat(ansiFloat);
      }
      return parseFloat(string);
    },
    getStations: async function () {
      let payload = {
        longitude: this.$globalState.searchParams.location.longitude,
        latitude: this.$globalState.searchParams.location.latitude,
        type: "e5",
        radius: 10,
      };
      // MOCKUP PAYLOAD
      // let payload = {
      //   longitude: 13.374495,
      //   latitude: 52.528155,
      //   type: this.selectedCar.fuel_type,
      //   radius: 10,
      // };
      let response = await fetch(this.$apiUrl + "/station/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      let responseData = await response.json();

      if (responseData.state) {
        for (let i = 0; i < responseData.stations.length; i++) {
          let station = responseData.stations[i];
          station.displayName =
            responseData.stations[i].brand +
            " - " +
            responseData.stations[i].name;
          this.gasStations.push(station);
        }
        this.gasStations = responseData.stations;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Gas Stations",
          message: responseData.msg,
        };
        this.$globalState.addNotification(notify);
      }
    },
    addRefuelAction: function () {
      let tripConsumption =
        this.parseFloatGerman(this.exampleAction.action.litres) /
        ((this.parseFloatGerman(this.exampleAction.action.newMileage) -
          this.parseFloatGerman(this.exampleData.car.mileage)) /
          100);

      let allRefuelActions = this.exampleData.items;

      allRefuelActions.push({
        trip_consumption: tripConsumption,
        test: "test",
      });

      let averageConsumptionSum = 0;
      for (let i = 0; i < allRefuelActions.length; i++) {
        averageConsumptionSum =
          averageConsumptionSum + allRefuelActions[i].trip_consumption;
      }

      let carConsumption = averageConsumptionSum / allRefuelActions.length;

      allRefuelActions.pop();

      let action = {
        id: "8cddcd61-e603-4077-b709-13ef84753259",
        user_id: "6d235de5-66f3-44e9-8455-b7d45a04e477",
        car_id: "24aadd76-5a60-4e39-9ee2-856636c0ffa8",
        trip:
          this.exampleAction.action.newMileage - this.exampleData.car.mileage,
        trip_consumption: tripConsumption,
        car_consumption: carConsumption,
        price_per_litre:
          this.parseFloatGerman(this.exampleAction.action.price) /
          this.parseFloatGerman(this.exampleAction.action.litres),
        station_id: this.exampleAction.action.gasStation.id,
        fuel_type: "e5",
        litres: this.parseFloatGerman(this.exampleAction.action.litres),
        price: this.parseFloatGerman(this.exampleAction.action.price),
        created: Date.now(),
      };

      this.exampleData.items.push(action);
    },
    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    higherThanCurrentMileage: function (input) {
      if (input > this.exampleData.car.mileage) {
        return true;
      }
      return "Must be higher than Current";
    },
  },
};
</script>
<style scoped>
.theme--dark.v-list {
  background: var(--v-primary-base);
}
</style>
