<template>
  <v-card color="primary" width="400">
    <v-container>
      <v-row>
        <v-col
          class="d-flex justify-center text-h5 font-weight-bold secondary--text"
        >
          <span>Create Car</span>
        </v-col>
      </v-row>
      <v-form v-model="validCar" ref="createCarForm">
        <v-row>
          <v-col>
            <v-text-field
              color="secondary"
              label="Brand"
              v-model="car.brand"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              color="secondary"
              label="Type"
              v-model="car.model"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              color="secondary"
              item-color="secondary"
              label="Fuel Type"
              :items="fuelTypes"
              return-object
              item-text="name"
              v-model="car.fuelType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              color="secondary"
              label="Current Mileage"
              v-model="car.mileage"
              :rules="[notEmpty]"
              type="number"
              @keyup.enter="createCar"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn color="secondary" :disabled="!validCar" @click="createCar">
            Create Car
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
// EMIT EVENTS:
// update:selectedCar -> new car object from supabase is passed

import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "createCar",
  data() {
    return {
      validCar: false,
      car: {
        brand: "",
        model: "",
        mileage: "",
        fuelType: "",
      },
      fuelTypes: [
        {
          name: "Super E10",
          value: "e10",
        },
        {
          name: "Super E5",
          value: "e5",
        },
        {
          name: "Diesel",
          value: "diesel",
        },
      ],
    };
  },
  methods: {
    createCar: async function () {
      let response = await supabase.from("cars").insert([
        {
          user_id: supabase.auth.user().id,
          display_name: this.car.brand + " " + this.car.model,
          brand: this.car.brand,
          model: this.car.model,
          mileage: this.car.mileage.replace(",", "."),
          fuel_type: this.car.fuelType.value,
          updated: Date.now(),
          created: Date.now(),
        },
      ]);

      if (response.data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline:
            "Successfully added " +
            this.car.brand +
            " " +
            this.car.model +
            " to your Garage",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.$emit("createdCar");
        response.data[0];
        this.$refs.createCarForm.reset();
        this.$emit("update:selectedCar", response.data[0]);
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Adding Car to Garage",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Adding Car to Garage",
          message: "Failed adding Car! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
  },
};
</script>
<style scoped>
.theme--dark.v-list {
  background: var(--v-primary-base);
}
</style>
