<template>
  <div class="mb-3">
    <transition name="fade" mode="out-in">
      <v-btn
        fab
        fixed
        bottom
        right
        color="secondary"
        @click="$vuetify.goTo(0)"
        v-show="showScrollTopFab && scrollHeight > 0"
      >
        <v-icon large>mdi-chevron-up</v-icon>
      </v-btn>
    </transition>
    <heading text="Gas Stations in your Surrounding" />

    <!-- desktop-view -->
    <div class="hidden-xs-only">
      <v-row>
        <v-col class="hidden-md-and-down" cols="2"></v-col>
        <v-col lg="8">
          <v-toolbar
            rounded
            outlined
            color="primary"
            class="mb-6 pa-0"
            elevation="12"
          >
            <v-text-field
              solo
              flat
              class="mb-n7"
              prepend-inner-icon="mdi-map-marker-radius-outline"
              color="secondary"
              label="Radius in KM"
              type="number"
              v-model="$globalState.searchParams.radius"
              @keyup.enter="getStations($globalState.searchParams.radius, type)"
            ></v-text-field>
            <v-divider vertical class="mx-2"></v-divider>
            <v-select
              solo
              item-color="secondary"
              flat
              class="mb-n7"
              prepend-inner-icon="mdi-fuel"
              color="secondary"
              label="Gas-Type"
              :items="types"
              return-object
              v-model="type"
              item-text="name"
              @change="getStations($globalState.searchParams.radius, type)"
            ></v-select>
            <v-divider vertical class="mx-2"></v-divider>
            <div
              class="searchButton d-flex justify-center align-center"
              @click="getStations($globalState.searchParams.radius, type)"
            >
              <span
                class="
                  primary--text
                  font-weight-bold
                  text-button
                  hidden-sm-only
                "
                >Search Gas Stations</span
              >
              <span
                class="
                  primary--text
                  font-weight-bold
                  text-uppercase text-caption
                  hidden-xs-only hidden-md-and-up
                "
                >Search Stations</span
              >
              <v-icon color="primary" class="mt-n1">mdi-chevron-right</v-icon>
            </div>
          </v-toolbar>
        </v-col>
      </v-row>
    </div>

    <!-- mobile-view -->
    <v-row class="hidden-sm-and-up">
      <v-col>
        <v-toolbar
          rounded
          outlined
          color="primary"
          class="mb-6 pa-0"
          elevation="12"
        >
          <v-text-field
            solo
            flat
            class="mb-n7"
            prepend-inner-icon="mdi-map-marker-radius-outline"
            color="secondary"
            label="Radius in KM"
            type="number"
            v-model="$globalState.searchParams.radius"
            @keyup.enter="getStations($globalState.searchParams.radius, type)"
          ></v-text-field>
          <v-divider vertical class="mx-2"></v-divider>
          <v-select
            solo
            item-color="secondary"
            flat
            class="mb-n7"
            prepend-inner-icon="mdi-fuel"
            color="secondary"
            label="Gas-Type"
            :items="types"
            return-object
            v-model="type"
            item-text="name"
            @change="getStations($globalState.searchParams.radius, type)"
          ></v-select>
          <v-divider vertical class="mx-2"></v-divider>
          <div
            class="searchButton d-flex justify-center align-center"
            @click="getStations($globalState.searchParams.radius, type)"
          >
            <v-icon color="primary" class="mt-n1">mdi-magnify</v-icon>
          </div>
        </v-toolbar>
      </v-col>
    </v-row>

    <!-- table -->
    <v-row>
      <v-col class="hidden-md-and-down" cols="2"></v-col>
      <v-col lg="8">
        <v-card color="primary" elevation="10">
          <div>
            <v-row class="px-4">
              <v-col>
                <v-row>
                  <v-col class="pb-0">
                    <span class="text-h5 font-weight-bold secondary--text"
                      >Station List</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span class="text-caption"
                      >Click on a table row for a detailed view of the selected
                      Station</span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="hidden-xs-only" cols="5">
                <v-text-field
                  color="secondary"
                  prepend-inner-icon="mdi-magnify"
                  label="Search Station Brands"
                  v-model="stationSearch"
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex justify-end">
                <v-btn
                  icon
                  @click="getStations($globalState.searchParams.radius, type)"
                >
                  <v-icon :class="rotateClass" color="secondary"
                    >mdi-refresh</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="hidden-sm-and-up">
              <v-col class="px-7 py-0">
                <v-text-field
                  color="secondary"
                  prepend-inner-icon="mdi-magnify"
                  label="Search Station Brands"
                  v-model="stationSearch"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <v-data-table
            :headers="stations.headers"
            :items="stations.items"
            disable-pagination
            hide-default-footer
            class="primary"
            :loading="stations.loading"
            :search="stationSearch"
            mobile-breakpoint="720"
            :custom-sort="customSort"
          >
            <template v-slot:[`item.isOpen`]="{ item }">
              <span class="ml-n2">
                <v-icon v-if="item.isOpen" color="secondary"
                  >mdi-clock-check-outline</v-icon
                >
                <v-icon v-else color="error">mdi-clock-remove-outline</v-icon>
              </span>
            </template>
            <template v-slot:[`item.dist`]="{ item }">
              <span> {{ item.dist }} km </span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span class="secondary--text font-weight-bold">
                {{ item.price || " - " }}
              </span>
            </template>
            <template v-slot:[`item.isFavourite`]="{ item }">
              <v-tooltip
                left
                v-if="$globalState.isLoggedIn && !item.isFavourite"
                color="grey"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="changeFavouriteState(item)"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon class="px-2" color="grey">mdi-star-outline</v-icon>
                  </v-btn>
                </template>
                <span>Add to Favourites</span>
              </v-tooltip>
              <v-tooltip
                left
                v-if="$globalState.isLoggedIn && item.isFavourite"
                color="yellow"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="changeFavouriteState(item)"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon color="yellow">mdi-star</v-icon>
                  </v-btn>
                </template>
                <span class="black--text">Remove from Favourites</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="handleDetailView(item)"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon color="secondary">mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span class="black--text">Show Station Details</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import heading from "../components/heading.vue";
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "GasStations",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Gas-Stations",
      meta: [
        {
          name: "description",
          content:
            this.$applicationName +
            " displays all available Gas-Stations in your Surrounding. Find the cheapest & closest Gas-Station",
        },
      ],
    };
  },
  components: {
    heading,
  },
  data() {
    return {
      stationSearch: "",
      scrollHeight: -1,
      stationFavourites: [],
      showScrollTopFab: false,
      type: {
        name: "All",
        value: "all",
      },
      types: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Super E10",
          value: "e10",
        },
        {
          name: "Super E5",
          value: "e5",
        },
        {
          name: "Diesel",
          value: "diesel",
        },
      ],
      stations: {
        loading: true,
        headers: [
          {
            text: "Name",
            align: "center",
            sortable: true,
            value: "name",
          },
          {
            text: "Brand",
            align: "center",
            sortable: true,
            value: "brand",
          },
          {
            text: "Place",
            align: "center",
            sortable: true,
            value: "place",
          },
          {
            text: "Distance",
            align: "center",
            sortable: true,
            value: "dist",
          },
          {
            text: "Open",
            align: "center",
            sortable: true,
            value: "isOpen",
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            sortable: false,
          },
        ],
        items: [],
      },
    };
  },
  computed: {
    rotateClass: function () {
      if (this.stations.loading) {
        return "rotate";
      }
      return "";
    },
  },
  async mounted() {
    this.getStations(10, { name: "All", value: "all" });
    this.$globalState.searchParams.radius = null;
    this.type = null;

    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    scrollHeight: function () {
      this.showScrollTopFab = true;
      setTimeout(() => {
        this.showScrollTopFab = false;
      }, 4000);
    },
  },
  methods: {
    customSort(items, index, isDesc) {
      let indexString = null;
      if (index.length > 0) {
        indexString = index[0];
      }
      let isDescBool = null;
      if (isDesc.length > 0) {
        isDescBool = isDesc[0];
      }

      if (indexString) {
        items = items.filter((item) => {
          return item[indexString] != null;
        });
        items.sort((a, b) => {
          if (!isDescBool) {
            return this.compare(a[indexString], b[indexString]);
          } else {
            return this.compare(b[indexString], a[indexString]);
          }
        });
      }
      return items;
    },
    compare: function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
    changeFavouriteState: async function (item) {
      if (item.isFavourite) {
        //remove
        let response = await supabase
          .from("favouritestations")
          .delete()
          .match({ station_id: item.id });
        if (response.data.length > 0) {
          let alterIndex = this.stations.items
            .map((station) => station.id)
            .indexOf(item.id);
          this.stations.items[alterIndex].isFavourite = false;
          let stationItems = this.stations.items;
          this.stations.items = [];
          this.stations.items = stationItems;
        }
      } else {
        //add
        let response = await supabase.from("favouritestations").insert([
          {
            user_id: supabase.auth.user().id,
            station_id: item.id,
            created: Date.now(),
          },
        ]);
        if (response.data.length > 0) {
          let alterIndex = this.stations.items
            .map((station) => station.id)
            .indexOf(item.id);
          this.stations.items[alterIndex].isFavourite = true;
          let stationItems = this.stations.items;
          this.stations.items = [];
          this.stations.items = stationItems;
        }
      }
    },
    isFavourite: function (id) {
      if (this.stationFavourites.includes(id)) {
        return true;
      }
      return false;
    },
    getFavourites: async function () {
      let response = await supabase.from("favouritestations").select();
      for (let i = 0; i < response.data.length; i++) {
        this.stationFavourites.push(response.data[i].station_id);
      }
    },
    handleScroll() {
      this.scrollHeight = window.scrollY;
    },
    reloadPage: function () {
      location.reload();
    },
    getStations: async function (radius, type) {
      this.stations.loading = true;
      if (!radius) {
        radius = 10;
      }
      if (!type) {
        type = {
          name: "All",
          value: "all",
        };
      }

      await this.$getLocation({ enableHighAccuracy: true }).then(
        (coordinates) => {
          this.$globalState.searchParams.location = {
            longitude: coordinates.lng,
            latitude: coordinates.lat,
          };
        }
      );

      let payload = {
        longitude: this.$globalState.searchParams.location.longitude,
        latitude: this.$globalState.searchParams.location.latitude,
        type: type.value,
        radius: radius,
      };

      let response = await fetch(this.$apiUrl + "/station/list", {
        method: "POST",
        body: JSON.stringify(payload),
      });

      let responseData = await response.json();

      if (responseData.state) {
        this.stations.items = responseData.stations;
        if (this.$globalState.isLoggedIn) {
          await this.getFavourites();
          this.stations.items.forEach((station) => {
            station.isFavourite = this.isFavourite(station.id);
          });
          this.stations.headers.splice(this.stations.headers.length - 1, 0, {
            text: "Favourite",
            align: "center",
            sortable: true,
            value: "isFavourite",
          });
        }

        this.stations.loading = false;
        if (type.value != "all") {
          this.stations.headers = this.stations.headers.filter(function (
            header
          ) {
            return header.value !== "price";
          });
          this.stations.headers.push({
            text: type.name + " Price",
            align: "center",
            sortable: true,
            value: "price",
          });
        } else {
          this.stations.headers = this.stations.headers.filter(function (
            header
          ) {
            return header.value !== "price";
          });
        }
      }
    },
    handleDetailView: function (item) {
      window.open(this.$siteUrl + "/stationdetail/" + item.id, "_blank");
    },
  },
};
</script>

<style scoped>
.theme--dark.v-list {
  background: var(--v-primary-base);
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}
::v-deep .v-input__slot {
  background-color: var(--v-primary-base) !important;
}
.searchButton {
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
  width: 33%;
  height: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: -2px;
  margin-right: -1px;
  margin-left: -8px;
  cursor: pointer;
}
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* transition for fab button */
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-leave-to,
.fade-enter {
  opacity: 0;
}
</style>
