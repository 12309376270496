<template>
  <div class="mb-3">
    <v-row>
      <v-col class="d-flex justify-center">
        <span class="text-h4 font-weight-bold secondary--text"
          >Reset Password</span
        >
      </v-col>
    </v-row>
    <v-form v-model="reset.valid">
      <v-row>
        <v-col class="hidden-sm-and-down" cols="3"></v-col>
        <v-col md="6">
          <v-text-field
            label="New Password"
            color="secondary"
            v-model="reset.password"
            :rules="[notEmpty, minPasswordRequirements]"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" cols="3"></v-col>
        <v-col md="6">
          <v-text-field
            label="Repeat New Password"
            color="secondary"
            v-model="reset.password2"
            :rules="[notEmpty, minPasswordRequirements, samePassword]"
            @keyup.enter="handleNewPassword"
            type="password"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3"></v-col>
      <v-col md="6" class="d-flex justify-center">
        <v-btn
          color="secondary"
          :disabled="!reset.valid"
          @click="handleNewPassword"
          >Set new Password</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "ResetPassword",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Password-Reset",
      meta: [
        {
          name: "description",
          content:
            "Reset your password for your " +
            this.$applicationName +
            " profile",
        },
      ],
    };
  },
  data() {
    return {
      reset: {
        valid: false,
        password: "",
        password2: "",
      },
    };
  },
  methods: {
    handleNewPassword: async function () {
      await supabase.auth.api.updateUser(this.$route.query.token, {
        password: this.reset.password,
      });
      this.$router.push({ path: "/" });
      this.$router.push({ path: "/tracker" });
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    minPasswordRequirements: function (input) {
      let reUpperCase = /^(?=.*[A-Z])/;
      let reLowerCase = /^(?=.*[a-z])/;
      let reNumber = /^(?=.*\d)/;
      let reSpecial = /^(?=.*[-+_!@#$%^&*.,?])/;
      let reLength = /^.{8,}$/;
      let upperCase = reUpperCase.test(input);
      let lowerCase = reLowerCase.test(input);
      let number = reNumber.test(input);
      let special = reSpecial.test(input);
      let length = reLength.test(input);

      if (upperCase && lowerCase && number && special && length) {
        return true;
      } else {
        let errorString = "Minimum Requirements: ";

        if (!upperCase) {
          errorString = errorString + "1 Uppercase, ";
        }
        if (!lowerCase) {
          errorString = errorString + "1 Lowercase, ";
        }
        if (!number) {
          errorString = errorString + "1 Number, ";
        }
        if (!special) {
          errorString = errorString + "1 Special Character (-+_!@#$%^&*.,?), ";
        }
        if (!length) {
          errorString = errorString + "8 Characters in Total";
        }
        if (errorString.slice(-2) === ", ") {
          errorString = errorString.slice(0, -2);
        }
        return errorString;
      }
    },
    samePassword: function (input) {
      if (input === this.reset.password) {
        return true;
      }
      return "Passwords don't match!";
    },
  },
};
</script>
<style scoped></style>
