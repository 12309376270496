export default {
  primary: {
    base: "#11181d",
  },
  secondary: {
    base: "#13d389",
    lighten1: "#2bd996",
    lighten2: "#45e6a8",
    lighten3: "#69f5bf",
    darken1: "#11a66c",
    darken2: "#108f5e",
    darken3: "#0d784f",
  },
  error: {
    base: "#f44336",
  },
  warning: {
    base: "#ffd703",
  },
  info: {
    base: "#03A9F4",
  },
  success: {
    base: "#4caf50",
  },
  pending: {
    base: "#8a8a8a",
  },
  background: {
    base: "#20272E",
  },
  text: {
    base: "#fff",
  },
  theme: {
    base: "#000",
  },
};
