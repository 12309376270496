<template>
  <div class="mb-3">
    <heading text="Consumption Tracker" />
    <!-- desktop-view -->
    <div class="hidden-sm-and-down">
      <v-row>
        <v-col class="hidden-md-and-down" cols="3"></v-col>
        <v-col lg="6">
          <v-toolbar
            rounded
            outlined
            color="primary"
            class="mb-6 pa-0"
            elevation="12"
          >
            <v-select
              solo
              flat
              item-color="secondary"
              class="mb-n7"
              prepend-inner-icon="mdi-car"
              label="Select a Car"
              color="secondary"
              :items="cars"
              item-text="display_name"
              v-model="selectedCar"
              return-object
            ></v-select>
            <v-divider vertical class="mx-2"></v-divider>
            <v-dialog v-model="createCarDialog">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="searchButton d-flex justify-center align-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary" class="mt-n1">mdi-plus</v-icon>
                  <span class="primary--text font-weight-bold text-button"
                    >Add Car</span
                  >
                </div>
              </template>
              <createCar
                @createdCar="getUserCars() && (createCarDialog = false)"
                v-bind:selectedCar.sync="selectedCar"
              />
            </v-dialog>
          </v-toolbar>
        </v-col>
      </v-row>
      <div v-if="selectedCar">
        <v-row>
          <v-col>
            <carInfo
              v-bind:selectedCar.sync="selectedCar"
              v-bind:refuelActionItems.sync="refuelActions.items"
              @getUserCars="getUserCars()"
              :cardTitle="'Car Info - ' + selectedCar.display_name"
              :elevation="10"
            />
          </v-col>
          <v-col>
            <refuelCar
              v-bind:selectedCar.sync="selectedCar"
              v-bind:refuelActionItems.sync="refuelActions.items"
              :cardTitle="'Refuel - ' + selectedCar.display_name"
              :elevation="10"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <refuelHistory
              v-bind:refuelActionItems.sync="refuelActions.items"
              v-bind:selectedCar.sync="selectedCar"
              :elevation="10"
              :cardTitle="
                'Refuel History for ' + selectedCar.display_name + ' - '
              "
              noDataMessage="Will be displayed as soon as you have refueled at least one time!"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- mobile-view -->
    <div class="hidden-md-and-up">
      <v-row>
        <v-col>
          <v-toolbar
            rounded
            outlined
            color="primary"
            class="mb-6 pa-0"
            elevation="12"
          >
            <v-select
              solo
              flat
              class="mb-n7"
              prepend-inner-icon="mdi-car"
              label="Select a Car"
              color="secondary"
              item-color="secondary"
              :items="cars"
              item-text="display_name"
              v-model="selectedCar"
              return-object
            ></v-select>
            <v-divider vertical class="mx-2"></v-divider>
            <v-dialog v-model="createCarDialog">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="searchButton d-flex justify-center align-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary" class="mt-n1">mdi-plus</v-icon>
                  <span
                    class="
                      primary--text
                      font-weight-bold
                      text-button
                      hidden-xs-only
                    "
                    >Add Car</span
                  >
                </div>
              </template>
              <createCar
                @createdCar="getUserCars() && (createCarDialog = false)"
                v-bind:selectedCar.sync="selectedCar"
              />
            </v-dialog>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-expansion-panels v-if="selectedCar">
            <v-expansion-panel class="primary">
              <v-expansion-panel-header class="secondary--text"
                >Car-Info</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <carInfo
                      v-bind:selectedCar.sync="selectedCar"
                      v-bind:refuelActionItems.sync="refuelActions.items"
                      @getUserCars="getUserCars()"
                      :elevation="0"
                      :cardTitle="selectedCar.display_name"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="primary">
              <v-expansion-panel-header class="secondary--text"
                >Refuel {{ selectedCar.display_name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <refuelCar
                      v-bind:selectedCar.sync="selectedCar"
                      v-bind:refuelActionItems.sync="refuelActions.items"
                      :elevation="0"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="primary">
              <v-expansion-panel-header class="secondary--text"
                >Refuel History for {{ selectedCar.display_name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <refuelHistory
                      v-bind:refuelActionItems.sync="refuelActions.items"
                      v-bind:selectedCar.sync="selectedCar"
                      :elevation="0"
                      noDataMessage="Will be displayed as soon as you have refueled at least one time!"
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "@/plugins/vuetify/helper.js";

import createCar from "../components/createCar.vue";
import carInfo from "../components/carInfo.vue";
import refuelCar from "../components/refuelCar.vue";
import refuelHistory from "../components/refuelHistory.vue";
import heading from "../components/heading.vue";
export default {
  name: "Garage",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Garage",
    meta: [
      {
        name: "description",
        content:
          "Track your Trip-Consumption, Car-Consumption, Costs, Trip-Length, Times Refueled with ease and display them detailed either as a chart or table",
      },
    ],
  },
  components: {
    createCar,
    carInfo,
    refuelCar,
    refuelHistory,
    heading,
  },
  async mounted() {
    await this.getUserCars();
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    if (this.$route.query.carId) {
      this.selectedCar = this.cars.filter((car) => {
        return car.id === this.$route.query.carId;
      })[0];
    }
  },
  data() {
    return {
      cars: [],
      selectedCar: null,
      createCarDialog: false,
      refuelActions: {
        displayType: "graph",
        headers: [
          {
            text: "Timestamp",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Trip",
            align: "center",
            sortable: true,
            value: "trip",
          },
          {
            text: "Consumption",
            align: "center",
            sortable: true,
            value: "trip_consumption",
          },
          {
            text: "Fuel-Type",
            align: "center",
            sortable: true,
            value: "fuel_type",
          },
          {
            text: "Litres",
            align: "center",
            sortable: true,
            value: "litres",
          },
          {
            text: "Price",
            align: "center",
            sortable: true,
            value: "price",
          },
          {
            text: "Price Per Litre",
            align: "center",
            sortable: true,
            value: "price_per_litre",
          },
          {
            text: "Gas-Station",
            align: "center",
            sortable: true,
            value: "station_id",
          },
        ],
        items: [],
      },
      refuelActionChart: {
        series: [
          {
            type: "line",
            data: [],
            name: "Trip (km)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Litres",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Trip Consumption (l/100km)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Car Consumption (l/100km)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Overall-Price (€)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Price/Litre (€)",
            emphasis: {
              focus: "series",
            },
          },
        ],
        title: "",
        xAxisData: [],
        legendData: [
          "Trip (km)",
          "Litres",
          "Trip Consumption (l/100km)",
          "Car Consumption (l/100km)",
          "Overall-Price (€)",
          "Price/Litre (€)",
        ],
      },
    };
  },
  watch: {
    selectedCar: async function () {
      if (this.selectedCar) {
        this.addCarIdToLocation(this.selectedCar.id);
        await this.getRefuelActionsForCar();
      }
    },
  },
  methods: {
    addCarIdToLocation(carId) {
      history.pushState({}, null, this.$route.path + "?carId=" + carId);
    },
    getUserCars: async function () {
      this.cars = [];
      let response = await supabase.from("cars").select();

      if (response.data.length > 0) {
        let cars = [];

        for (let i = 0; i < response.data.length; i++) {
          let car = response.data[i];
          car.created = response.data[i].created;
          car.updated = response.data[i].updated;
          cars.push(car);
        }
        this.cars = cars;
        // if (this.cars.length === 1) {
        //   this.selectedCar = this.cars[0];
        // }
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Garage",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else if (response.data.length != 0) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed fetching Garage",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },
    createDate: function (epoch) {
      return createDate(epoch);
    },
    getRefuelActionsForCar: async function () {
      let response = await supabase
        .from("refuelactions")
        .select()
        .match({ car_id: this.selectedCar.id });
      if (response.data.length > 0) {
        this.refuelActions.items = response.data;
      } else if (response.data.length === 0) {
        this.refuelActions.items = [];
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Refuel-Action",
          message:
            "Can't display Refuel-History since there haven't been any Refuel Actions so far!",
        };
        this.$globalState.addNotification(notify);
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Refuel-Action",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Refuel-Action",
          message: "Failed fetching Refuel-Actions",
        };
        this.$globalState.addNotification(notify);
      }
    },
  },
};
</script>
<style scoped>
.theme--dark.v-list {
  background: var(--v-primary-base);
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}
::v-deep .v-input__slot {
  background-color: var(--v-primary-base) !important;
}
.searchButton {
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
  width: 33%;
  height: 100%;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: -2px;
  margin-right: -1px;
  margin-left: -8px;
  cursor: pointer;
}
</style>
