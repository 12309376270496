<template>
  <div class="mb-3">
    <heading text="My Profile" />
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3"></v-col>
      <v-col md="6">
        <v-card color="primary" elevation="10">
          <v-card-title>
            <v-row>
              <v-col>
                <span class="secondary--text text-h5 font-weight-bold">
                  {{ profile.firstName }} {{ profile.lastName }}
                </span>
              </v-col>
              <v-col cols="2" class="d-flex justify-end" v-if="!editProfile">
                <v-btn icon @click="editProfile = true">
                  <v-icon color="secondary">mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="pendingDeleteConfirmation = true">
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-container>
            <v-form v-model="validChanges">
              <v-row>
                <v-col class="d-flex align-center"> First Name </v-col>
                <v-col class="py-0" v-if="editProfile">
                  <v-text-field
                    label="First Name"
                    color="secondary"
                    v-model="newProfile.firstName"
                    :placeholder="profile.firstName"
                    :rules="[notEmpty]"
                  >
                  </v-text-field>
                </v-col>
                <v-col v-else> {{ profile.firstName }} </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center"> Last Name </v-col>
                <v-col class="py-0" v-if="editProfile">
                  <v-text-field
                    label="Last Name"
                    color="secondary"
                    v-model="newProfile.lastName"
                    :placeholder="profile.lastName"
                    :rules="[notEmpty]"
                  >
                  </v-text-field>
                </v-col>
                <v-col v-else> {{ profile.lastName }} </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center"> E-Mail </v-col>
                <v-col class="py-0" v-if="editProfile">
                  <v-text-field
                    label="E-Mail"
                    color="secondary"
                    type="email"
                    v-model="newProfile.email"
                    :placeholder="profile.email"
                    :rules="[notEmpty, validEmail]"
                  >
                  </v-text-field>
                </v-col>
                <v-col v-else class="text-truncate">
                  {{ profile.email }}
                </v-col>
              </v-row>
            </v-form>
            <v-row v-if="profile.provider === 'email'">
              <v-col class="d-flex align-center"> Password </v-col>
              <v-col>
                <v-dialog v-model="changePassword.dialog">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="secondary" v-bind="attrs" v-on="on">
                      Change Password
                    </v-btn>
                  </template>
                  <v-card color="background" width="400">
                    <v-container>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <span class="text-h6 font-weight-bold"
                            >Password Change</span
                          >
                        </v-col>
                      </v-row>
                      <v-form v-model="changePassword.valid">
                        <v-row>
                          <v-col class="d-flex justify-center">
                            <v-text-field
                              color="secondary"
                              label="Password"
                              v-model="changePassword.user.password"
                              :rules="[notEmpty, minPasswordRequirements]"
                              type="password"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="d-flex justify-center">
                            <v-text-field
                              color="secondary"
                              label="Repeat Password"
                              v-model="changePassword.user.password2"
                              :rules="[
                                notEmpty,
                                minPasswordRequirements,
                                samePassword,
                              ]"
                              type="password"
                              @keyup.enter="updatePassword"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <v-btn
                            @click="updatePassword"
                            color="secondary"
                            :loading="changePassword.loading"
                            :disabled="!changePassword.valid"
                            >Change</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center"> Account Provider </v-col>
              <v-col> {{ providerMap[profile.provider] }} </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center"> Amount of Cars </v-col>
              <v-col> {{ profile.amountCars }} </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex align-center"> Member Since </v-col>
              <v-col> {{ profile.memberSince }} </v-col>
            </v-row>
            <v-row v-if="editProfile">
              <v-col class="d-flex justify-end">
                <v-btn @click="resetNewProfile" color="background"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col class="d-flex justify-start">
                <v-btn
                  color="secondary"
                  :disabled="!newChanges || !validChanges"
                  @click="updateUserProfile"
                  :loading="loadingSavingChanges"
                >
                  Save Changes
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay v-model="pendingDeleteConfirmation">
      <v-card
        color="background"
        outlined
        class="overlay"
        :light="!$vuetify.theme.dark"
      >
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >Delete Account</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span>
                Are you sure you want to delete your account associated with
                {{ profile.email }}?<br />You'll loose all your data, including
                all statistics of your {{ profile.amountCars }} Cars!</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="error--text">
              <span class="font-weight-bold"
                >This action can not be reverted!</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="pendingDeleteConfirmation = !pendingDeleteConfirmation"
                color="primary"
                text
                class="mr-3 cancel text--text"
                >Cancel</v-btn
              >

              <v-btn @click="deleteUser" color="error" text class="delete"
                >Yes, delete</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "@/plugins/vuetify/helper.js";
import heading from "../components/heading.vue";
export default {
  name: "Profile",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.profile.firstName + "'s Profile",
      meta: [
        {
          name: "description",
          content: "Display your " + +" profile",
        },
      ],
    };
  },
  components: {
    heading,
  },
  data() {
    return {
      pendingDeleteConfirmation: false,
      changePassword: {
        dialog: false,
        loading: false,
        valid: false,
        user: {
          password: "",
          password2: "",
        },
      },
      newProfile: {
        firstName: "",
        lastName: "",
        email: "",
      },
      profile: {
        firstName: "",
        lastName: "",
        email: "",
        amountCars: 0,
        memberSince: "",
        provider: "",
      },
      editProfile: false,
      validChanges: false,
      loadingSavingChanges: false,
      providerMap: {
        email: "E-Mail",
        discord: "Discord",
        google: "Google",
        spotify: "Spotify",
        apple: "Apple",
      },
    };
  },
  computed: {
    newChanges: function () {
      if (
        this.profile.firstName != this.newProfile.firstName ||
        this.profile.lastName != this.newProfile.lastName ||
        this.profile.email != this.newProfile.email
      ) {
        if (
          this.newProfile.firstName &&
          this.newProfile.lastName &&
          this.newProfile.email
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  async mounted() {
    await this.getUserProfile();
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    this.resetNewProfile();
  },
  methods: {
    getUserProfile: async function () {
      let response = await supabase.from("profiles").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Profile",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else if (response.data.length > 0) {
        this.profile.firstName = response.data[0].name;
        this.profile.lastName = response.data[0].last_name;
        this.profile.memberSince = createDate(response.data[0].created);
        this.profile.email = supabase.auth.user().email;
        this.profile.provider = response.data[0].provider;

        response = await supabase
          .from("cars")
          .select("id", { count: "exact", head: true });

        if (!isNaN(response.count)) {
          this.profile.amountCars = response.count;
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Fetching Profile",
            message: "Failed counting your Cars! Try again later!",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Profile",
          message: "Failed fetching Profile! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    resetNewProfile: function () {
      this.editProfile = false;
      this.newProfile.firstName = this.profile.firstName;
      this.newProfile.lastName = this.profile.lastName;
      this.newProfile.email = this.profile.email;
    },
    updateUserProfile: async function () {
      this.loadingSavingChanges = true;
      let response = await supabase
        .from("profiles")
        .update({
          name: this.newProfile.firstName,
          last_name: this.newProfile.lastName,
          updated: Date.now(),
        })
        .match({ id: supabase.auth.user().id });

      if (response.data.length > 0) {
        if (this.newProfile.email != this.profile.email) {
          response = await supabase.auth.update({
            email: this.newProfile.email,
          });

          if (response.user.new_email === this.newProfile.email) {
            let notify = {
              duration: 8000,
              type: "success",
              headline: "E-Mail-Address Change",
              message:
                "We sent you an E-Mail to confirm the change of your E-Mail-Address to '" +
                this.profile.email +
                "' - Please check your Inbox!",
            };
            this.$globalState.addNotification(notify);
          } else {
            let notify = {
              duration: 4000,
              type: "error",
              headline: "E-Mail-Address Change",
              message:
                "Failed changing your E-Mail-Address. Please try again later!",
            };
            this.$globalState.addNotification(notify);
          }
        } else {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Updating Profile",
            message: "Successfully updated your Profile!",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Profile",
          message: "Failed updating your Profile. Please try again later!",
        };
        this.$globalState.addNotification(notify);
      }

      await this.getUserProfile();
      this.editProfile = false;
      this.loadingSavingChanges = false;
    },
    updatePassword: async function () {
      this.changePassword.loading = true;
      let response = await supabase.auth.update({
        password: this.changePassword.user.password,
      });
      if (response.data.role === "authenticated") {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Password Change",
          message: "Successfully changed Password",
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Password Change",
          message: "Failed changing Password! Please try again later!",
        };
        this.$globalState.addNotification(notify);
      }
      this.changePassword.dialog = false;
      this.changePassword.loading = false;
    },
    deleteUser: async function () {
      let userId = supabase.auth.user().id;
      if (userId) {
        let payload = {
          userId,
        };
        let response = await fetch(this.$apiUrl + "/auth/deleteUser", {
          method: "POST",
          body: JSON.stringify(payload),
        });
        let responseData = await response.json();

        if (responseData.state) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Deleting Profile",
            message: responseData.msg,
          };
          this.$globalState.addNotification(notify);
          this.pendingDeleteConfirmation = false;
          await supabase.auth.signOut();
          this.$globalState.isLoggedIn = false;
          this.$router.push({ path: "/" });
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Deleting Profile",
            message: response.msg,
          };
          this.$globalState.addNotification(notify);
          this.pendingDeleteConfirmation = false;
        }
      } else {
        //Could not get active user id
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Profile",
          message: "Failed deleting Profile. Please relogin and try again!",
        };
        this.$globalState.addNotification(notify);
      }
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    validEmail: function (input) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(input).toLowerCase())) {
        return true;
      }
      return "Invalid E-Mail";
    },
    minPasswordRequirements: function (input) {
      let reUpperCase = /^(?=.*[A-Z])/;
      let reLowerCase = /^(?=.*[a-z])/;
      let reNumber = /^(?=.*\d)/;
      let reSpecial = /^(?=.*[-+_!@#$%^&*.,?])/;
      let reLength = /^.{8,}$/;
      let upperCase = reUpperCase.test(input);
      let lowerCase = reLowerCase.test(input);
      let number = reNumber.test(input);
      let special = reSpecial.test(input);
      let length = reLength.test(input);

      if (upperCase && lowerCase && number && special && length) {
        return true;
      } else {
        let errorString = "Minimum Requirements: ";

        if (!upperCase) {
          errorString = errorString + "1 Uppercase, ";
        }
        if (!lowerCase) {
          errorString = errorString + "1 Lowercase, ";
        }
        if (!number) {
          errorString = errorString + "1 Number, ";
        }
        if (!special) {
          errorString = errorString + "1 Special Character (-+_!@#$%^&*.,?), ";
        }
        if (!length) {
          errorString = errorString + "8 Characters in Total";
        }
        if (errorString.slice(-2) === ", ") {
          errorString = errorString.slice(0, -2);
        }
        return errorString;
      }
    },
    samePassword: function (input) {
      if (input === this.changePassword.user.password) {
        return true;
      }
      return "Passwords don't match!";
    },
  },
};
</script>
<style scoped>
.overlay {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.cancel {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.delete {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-error-base);
}
</style>
