<template>
  <v-chart
    :style="{ height: height }"
    autoresize
    :option="line"
    :group="group"
  />
</template>

<script>
import VChart from "vue-echarts";
import "../plugins/eCharts/eChartsBundle.js";

export default {
  name: "lineChart",
  props: ["series", "title", "height", "group", "xAxisData", "legendData"],
  components: {
    VChart,
  },
  data() {
    return {};
  },
  computed: {
    line() {
      return {
        title: {
          text: this.title,
          x: "start",
        },
        legend: {
          data: this.legendData,
          textStyle: {
            color: this.$vuetify.theme.currentTheme.text.base,
          },
        },
        xAxis: {
          type: "category",
          data: this.xAxisData,
          splitLine: {
            // show: false,
            type: "dashed",
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            // show: false,
            lineStyle: {
              color: this.$vuetify.theme.currentTheme.background.base,
              type: "dashed",
            },
          },
        },
        series: this.series,
        toolbox: {
          orient: "vertical",
          // left: "right",
          right: "6%",
          top: "center",
          showTitle: false,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
            magicType: { type: ["line", "bar"] },
          },
        },
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
          backgroundColor: this.$vuetify.theme.currentTheme.background.base,
          borderColor: this.$vuetify.theme.currentTheme.background.base,
          textStyle: {
            color: this.$vuetify.theme.currentTheme.text.base,
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 10,
          },
        ],
      };
    },
  },
};
</script>

<style scoped></style>
