<template>
  <div>
    <heading text="Report a Bug" />
    <v-row>
      <v-col class="hidden-sm-and-down" cols="3"></v-col>
      <v-col md="6">
        <v-stepper class="background py-0" flat v-model="step" vertical>
          <v-stepper-step step="1" color="secondary" :complete="step > 1">
            Basic Information
          </v-stepper-step>
          <v-stepper-content step="1">
            <div class="hidden-md-and-up">
              <v-form v-model="validStep[0]" ref="basicInfo">
                <v-row>
                  <v-col>
                    <span
                      >You found a bug in {{ this.$applicationName }}? Report it
                      to the DEV-Team to get it out of the code :)
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Short Description (What happend? What doesn't work?)"
                      color="secondary"
                      :rules="[notEmpty]"
                      v-model="bug.description"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="Where did you find the Bug?"
                      :rules="[notEmpty]"
                      :items="viewItems"
                      v-model="bug.view"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="Severity of the Bug"
                      :rules="[notEmpty]"
                      :items="severitys"
                      v-model="bug.severity"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="What platform are your using?"
                      :rules="[notEmpty]"
                      :items="platforms"
                      v-model="bug.platform"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="Which Browser are you using?"
                      :rules="[notEmpty]"
                      :items="browsers"
                      v-model="bug.browser"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end mb-2">
                  <v-btn
                    color="secondary"
                    @click="step++"
                    :disabled="!validStep[0]"
                    >Next</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <div class="hidden-sm-and-down">
              <v-form v-model="validStep[0]">
                <v-row>
                  <v-col>
                    <span
                      >You found a bug in {{ this.$applicationName }}? Report it
                      to the DEV-Team to get it out of the code :)
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      label="Short Description (What happend? What doesn't work?)"
                      color="secondary"
                      :rules="[notEmpty]"
                      v-model="bug.description"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="Where did you find the Bug?"
                      :rules="[notEmpty]"
                      :items="viewItems"
                      v-model="bug.view"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="Severity of the Bug"
                      :rules="[notEmpty]"
                      :items="severitys"
                      v-model="bug.severity"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="What platform are your using?"
                      :rules="[notEmpty]"
                      :items="platforms"
                      v-model="bug.platform"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-select
                      color="secondary"
                      label="Which Browser are you using?"
                      :rules="[notEmpty]"
                      :items="browsers"
                      v-model="bug.browser"
                      item-color="secondary"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end mb-2">
                  <v-btn
                    color="secondary"
                    @click="step++"
                    :disabled="!validStep[0]"
                    >Next</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-step step="2" color="secondary" :complete="step > 2">
            Steps to Reproduce
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-form v-model="validStep[1]" ref="steps">
              <v-row>
                <v-col>
                  <v-textarea
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="bug.reproduceSteps"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mb-2">
                <v-btn color="primary" @click="step--" class="mr-2"
                  >Previous</v-btn
                >
                <v-btn
                  color="secondary"
                  @click="step++"
                  :disabled="!validStep[1]"
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="3" color="secondary" :complete="step > 3"
            >Expected Behavior</v-stepper-step
          >
          <v-stepper-content step="3">
            <v-form v-model="validStep[2]" ref="expected">
              <v-row>
                <v-col>
                  <v-textarea
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="bug.expectedBehavior"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mb-2">
                <v-btn color="primary" @click="step--" class="mr-2"
                  >Previous</v-btn
                >
                <v-btn
                  color="secondary"
                  @click="step++"
                  :disabled="!validStep[2]"
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="4" color="secondary" :complete="step > 3"
            >Actual Behavior</v-stepper-step
          >
          <v-stepper-content step="4">
            <v-form v-model="validStep[3]" ref="actual">
              <v-row>
                <v-col>
                  <v-textarea
                    color="secondary"
                    :rules="[notEmpty]"
                    v-model="bug.actualBehavior"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mb-2">
                <v-btn color="primary" @click="step--" class="mr-2"
                  >Previous</v-btn
                >
                <v-btn
                  color="secondary"
                  @click="step++"
                  :disabled="!validStep[3]"
                  >Next</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="5" color="secondary" :complete="step > 4"
            >Additional Information</v-stepper-step
          >
          <v-stepper-content step="5">
            <v-row>
              <v-col>
                <v-form ref="addInfo">
                  <v-textarea
                    color="secondary"
                    v-model="bug.additionalInfo"
                  ></v-textarea>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mb-2">
                <v-btn color="primary" @click="step--" class="mr-2"
                  >Previous</v-btn
                >
                <v-btn color="secondary" @click="step++">Next</v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="6" color="secondary" :complete="step > 5"
            >Summary of the Bug</v-stepper-step
          >
          <v-stepper-content step="6">
            <v-row>
              <v-col class="mb-4">
                <span class="text-h6 secondary--text">
                  {{ bug.description }}
                </span>
              </v-col>
            </v-row>
            <v-expansion-panels>
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="secondary--text">
                  Basic Information
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <span class="font-weight-bold">View: </span>
                      {{ bug.view }}
                    </v-col>
                    <v-col>
                      <span class="font-weight-bold">Severity: </span>
                      {{ bug.severity }}
                    </v-col>
                    <v-col>
                      <span class="font-weight-bold">Platform: </span>
                      {{ bug.platform }}
                    </v-col>
                    <v-col>
                      <span class="font-weight-bold">Browser: </span>
                      {{ bug.browser }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="secondary--text">
                  Steps to Reproduce
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="text-caption">
                      {{ bug.reproduceSteps }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="secondary--text">
                  Expected Behavior
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="text-caption">
                      {{ bug.expectedBehavior }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="secondary--text">
                  Actual Behavior
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="text-caption">
                      {{ bug.actualBehavior }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="primary">
                <v-expansion-panel-header class="secondary--text">
                  Additional Information
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="text-caption">
                      {{ bug.additionalInfo || "None" }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mb-2">
                <v-btn color="primary" @click="step--" class="mr-2"
                  >Previous</v-btn
                >
                <v-btn color="error" text class="report mr-2" @click="reportBug"
                  >Report</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import heading from "../components/heading.vue";
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "ReportBug",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Report a Bug",
      meta: [
        {
          name: "description",
          content: "Report a Bug to the " + this.$applicationName + " DEV-Team",
        },
      ],
    };
  },
  components: {
    heading,
  },
  data() {
    return {
      step: 1,
      validStep: [false, false, false, false],
      bug: {
        description: "",
        view: "",
        severity: "",
        platform: "",
        browser: "",
        reproduceSteps: "",
        expectedBehavior: "",
        actualBehavior: "",
        additionalInfo: "",
      },
      severitys: ["Low", "Medium", "High", "Critical"],
      platforms: [
        "Windows",
        "MacOS",
        "Linux",
        "Android",
        "iOS",
        "Other (Specify in Text)",
      ],
      browsers: [
        "Chrome",
        "Safari",
        "Opera",
        "Firefox",
        "Microsoft Edge",
        "Internet Explorer",
        "Other (Specify in Text)",
      ],
    };
  },
  computed: {
    viewItems: function () {
      let routeNames = [];
      for (let i = 0; i < this.$router.options.routes.length; i++) {
        routeNames.push(this.$router.options.routes[i].title);
      }
      return routeNames;
    },
  },
  methods: {
    reportBug: async function () {
      let userId = null;
      if (supabase.auth.user()) {
        userId = supabase.auth.user().id;
      }
      let insertPayload = {
        description: this.bug.description,
        view: this.bug.view,
        severity: this.bug.severity,
        platform: this.bug.platform,
        browser: this.bug.browser,
        reproduce_steps: this.bug.reproduceSteps,
        expected_behavior: this.bug.expectedBehavior,
        actual_behavior: this.bug.actualBehavior,
        additional_information: this.bug.additionalInfo,
        user_id: userId,
        updated: Date.now(),
        created: Date.now(),
      };
      let response = await supabase.from("bugs").insert([insertPayload]);

      if (response.data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Successfully added reported Bug",
          message: "Thanks for reporting :)",
        };
        this.$globalState.addNotification(notify);
        setTimeout(() => {
          location.reload();
        }, 4000);
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Reporting Bug",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Reporting Bug",
          message: "Failed reporting Bug! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
  },
};
</script>
<style scoped>
.theme--dark.v-list {
  background: var(--v-background-base);
}
.overlay {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.report {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-error-base);
}
</style>
