<template>
  <v-app>
    <v-main id="app">
      <navBar class="pb-2" v-if="this.$route.name != 'Home'" />
      <div :class="responsiveMargin">
        <router-view v-if="!$globalState.loadingLocation" />
        <div v-else style="height: 88vh" class="center">
          <v-container>
            <v-row>
              <v-col>
                <heading text="Pending Location Grant" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="hidden-sm-and-down" cols="3"></v-col>
              <v-col md="6" class="text-center text-overline">
                Please grant {{ $applicationName }} to locate your Position. We
                need your position to search for gas-stations in your close
                surroundings. Your location won't be submitted to any third
                party.
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <notify />
    </v-main>
  </v-app>
</template>

<script>
import heading from "./components/heading.vue";
import navBar from "./components/navBar";
import notify from "./components/notification";
import { getProfile } from "./plugins/router/helper";
export default {
  name: "App",
  metaInfo() {
    return {
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.$applicationName,
    };
  },
  components: {
    notify,
    navBar,
    heading,
  },
  async mounted() {
    await getProfile();
    if (localStorage.getItem("redirect")) {
      let redirectName = localStorage.getItem("redirect");
      setTimeout(() => {
        this.$router.push({ name: redirectName });
      }, 1000);
      localStorage.removeItem("redirect");
    }
  },
  computed: {
    responsiveMargin: function () {
      if (this.$route.name != "Home") {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "mt-16 px-1 mx-2";
          case "sm":
            return "mt-16 px-1 mx-2";
          case "md":
            return "ml-16 px-1 mt-16 mr-3";
          case "lg":
            return "ml-16 px-1 mt-16 mr-3";
          case "xl":
            return "ml-16 px-1 mt-16 mr-3";
          default:
            return "ml-16 px-1 mt-16 mr-3";
        }
      }
      return "";
    },
  },
};
</script>

<style>
html {
  overflow: auto;
  overflow-x: hidden;
  background-color: var(--v-background-base);

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
#app {
  background-color: var(--v-background-base);
}
.center {
  display: grid;
  place-items: center;
}

/* width / */
::-webkit-scrollbar {
  width: 0.625em;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: var(--v-primary-base);
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: var(--v-background-base);
}

/* / Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--v-secondary-base);
}
</style>
