var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('heading',{attrs:{"text":"Development Overview"}}),_c('v-row',[_c('v-col',{staticClass:"hidden-md-and-down",attrs:{"cols":"1"}}),_c('v-col',{attrs:{"lg":"10"}},[_c('v-card',{attrs:{"color":"primary"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"\n              text-md-h6 text-subtitle-1\n              font-weight-bold\n              secondary--text\n            "},[_vm._v("Submitted Bugs")]),_c('v-spacer'),_c('div',{staticClass:"pr-5"},[_c('v-text-field',{staticStyle:{"width":"350px"},attrs:{"label":"Search","append-icon":"mdi-magnify","single-line":"","hide-details":"","color":"secondary"},model:{value:(_vm.bugTable.search),callback:function ($$v) {_vm.$set(_vm.bugTable, "search", $$v)},expression:"bugTable.search"}})],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getBugs}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"primary",attrs:{"search":_vm.bugTable.search,"headers":_vm.bugTable.headers,"items":_vm.bugTable.items,"disable-pagination":"","hide-default-footer":"","loading":_vm.bugTable.loading,"show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.view",fn:function(ref){
var item = ref.item;
return [(item.view != 'Detail view of a Station')?_c('a',{staticClass:"secondary--text",on:{"click":function($event){return _vm.pushView(item.view)}}},[_vm._v(" "+_vm._s(item.view)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.view)+" ")])]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [(item.severity === 'Low')?_c('span',{staticClass:"success--text"},[_vm._v("Low")]):(item.severity === 'Medium')?_c('span',{staticClass:"orange--text"},[_vm._v("Medium")]):(item.severity === 'High')?_c('span',{staticClass:"error--text"},[_vm._v("High")]):(item.severity === 'Critical')?_c('span',{staticClass:"error--text font-weight-bold text-decoration-underline"},[_vm._v("Critical")]):_vm._e()]}},{key:"item.is_open",fn:function(ref){
var item = ref.item;
return [(item.is_open)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle-outline")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle-outline")])]}},{key:"item.browser",fn:function(ref){
var item = ref.item;
return [(item.browser === 'Chrome')?_c('v-icon',[_vm._v("mdi-google-chrome")]):_vm._e(),(item.browser === 'Safari')?_c('v-icon',[_vm._v("mdi-apple-safari")]):_vm._e(),(item.browser === 'Opera')?_c('v-icon',[_vm._v("mdi-opera")]):_vm._e(),(item.browser === 'Firefox')?_c('v-icon',[_vm._v("mdi-firefox")]):_vm._e(),(item.browser === 'Microsoft Edge')?_c('v-icon',[_vm._v("mdi-microsoft-edge")]):_vm._e(),(item.browser === 'Internet Explorer')?_c('v-icon',[_vm._v("mdi-microsoft-internet-explorer")]):_vm._e()]}},{key:"item.platform",fn:function(ref){
var item = ref.item;
return [(item.platform === 'Windows')?_c('v-icon',[_vm._v("mdi-microsoft-windows")]):_vm._e(),(item.platform === 'MacOS')?_c('v-icon',[_vm._v("mdi-apple")]):_vm._e(),(item.platform === 'Linux')?_c('v-icon',[_vm._v("mdi-linux")]):_vm._e(),(item.platform === 'Android')?_c('v-icon',[_vm._v("mdi-android")]):_vm._e(),(item.platform === 'iOS')?_c('v-icon',[_vm._v("mdi-apple-ios")]):_vm._e(),(item.platform === 'Other')?_c('v-icon',[_vm._v("mdi-help")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{staticClass:"mt-5"},[_c('span',{staticClass:"font-weight-bold text-h6 secondary--text"},[_vm._v("Steps to Reproduce")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 mb-3"},[_vm._v(" "+_vm._s(item.reproduce_steps)+" ")])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold text-h6 secondary--text"},[_vm._v("Expected Behavior")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 mb-3"},[_vm._v(" "+_vm._s(item.expected_behavior)+" ")])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold text-h6 secondary--text"},[_vm._v("Actual Behavior")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 mb-3"},[_vm._v(" "+_vm._s(item.actual_behavior)+" ")])],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold text-h6 secondary--text"},[_vm._v("Additional Information")])])],1),_c('v-row',[(item.additional_information)?_c('v-col',{staticClass:"pt-0 mb-3"},[_vm._v(" "+_vm._s(item.additional_information)+" ")]):_c('v-col',{staticClass:"pt-0 mb-3"},[_vm._v("-")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.is_open)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.changeBugState(item)}}},[_vm._v("mdi-alert-circle-outline")]):_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.changeBugState(item)}}},[_vm._v("mdi-check-circle-outline")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.updateGhIssue(item)}}},[_vm._v("mdi-github")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteBug(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)],1),_c('v-overlay',{model:{value:(_vm.ghIssue.overlay),callback:function ($$v) {_vm.$set(_vm.ghIssue, "overlay", $$v)},expression:"ghIssue.overlay"}},[_c('v-card',{staticClass:"overlay",attrs:{"color":"background","outlined":"","light":!_vm.$vuetify.theme.dark}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h6 font-weight-bold secondary--text"},[_vm._v("GitHub Issue Link")])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("Enter here your Issue Link from Github, to connect this Bug to an Github Issue")])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Enter Issue Link","color":"secondary"},model:{value:(_vm.ghIssue.link),callback:function ($$v) {_vm.$set(_vm.ghIssue, "link", $$v)},expression:"ghIssue.link"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-3 cancel text--text",attrs:{"color":"background","text":""},on:{"click":function($event){_vm.ghIssue.overlay = !_vm.ghIssue.overlay}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"save",attrs:{"color":"success","text":"","disabled":!_vm.ghIssue.link},on:{"click":_vm.updateGhIssue}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }