<template>
  <div class="mb-3">
    <heading text="Calculate your Fuel Consumption" />
    <v-row>
      <v-col class="hidden-sm-and-down" cols="4"></v-col>
      <v-col md="4">
        <v-card color="primary" elevation="10">
          <v-card-title>
            <v-row>
              <v-col>
                <span class="secondary--text text-h5 font-weight-bold"
                  >Consumption Calculator</span
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  color="secondary"
                  label="Kilometres"
                  v-model="km"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  color="secondary"
                  label="Litres (Fuel)"
                  v-model="litres"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  color="secondary"
                  label="Consumption"
                  v-model="consumption"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import heading from "../components/heading.vue";
export default {
  name: "Calculator",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Calculator",
      meta: [
        {
          name: "description",
          content:
            "A simple Calculator to calculate your Fuel-Consumption by entering your Kilometers and spent Litres",
        },
      ],
    };
  },
  components: {
    heading,
  },
  data() {
    return {
      km: null,
      litres: null,
    };
  },
  computed: {
    consumption: function () {
      if (this.litres && this.km && this.litres != "" && this.km != "") {
        return (
          Number.parseFloat(this.litres / (this.km / 100)).toPrecision(4) +
          " l/100km"
        );
      } else {
        return "0 l/100km";
      }
    },
  },
};
</script>
<style scoped></style>
