<template>
  <div>
    <heading text="Your Favourite Gas Stations" />
    <v-row>
      <v-col class="hidden-md-and-down" cols="2"></v-col>
      <v-col lg="8">
        <v-card color="primary" elevation="10">
          <div>
            <v-row class="px-4">
              <v-col>
                <span class="text-h5 font-weight-bold secondary--text"
                  >Station List</span
                >
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn icon @click="getStations">
                  <v-icon :class="rotateClass" color="secondary"
                    >mdi-refresh</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="px-4 mt-n8">
              <v-col>
                <span class="text-caption"
                  >Click on a table row for a detailed view of the selected
                  Station</span
                >
              </v-col>
            </v-row>
          </div>
          <v-data-table
            :headers="stations.headers"
            :items="stations.items"
            disable-pagination
            hide-default-footer
            class="primary"
            :loading="stations.loading"
            @click:row="handleDetailView"
            no-data-text="You haven't marked any Gas Stations as your Favourite so far"
            :custom-sort="customSort"
          >
            <template v-slot:[`item.isOpen`]="{ item }">
              <span class="ml-n2">
                <v-icon v-if="item.isOpen" color="secondary"
                  >mdi-clock-check-outline</v-icon
                >
                <v-icon v-else color="error">mdi-clock-remove-outline</v-icon>
              </span>
            </template>
            <template v-slot:[`item.dist`]="{ item }">
              <span> {{ item.dist }} km </span>
            </template>
            <template v-slot:[`item.e5`]="{ item }">
              <span class="secondary--text font-weight-bold">
                {{ item.e5 || " - " }}
              </span>
            </template>
            <template v-slot:[`item.e10`]="{ item }">
              <span class="secondary--text font-weight-bold">
                {{ item.e10 || " - " }}
              </span>
            </template>
            <template v-slot:[`item.diesel`]="{ item }">
              <span class="secondary--text font-weight-bold">
                {{ item.diesel || " - " }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import heading from "../components/heading.vue";
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "StationFavourites",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Favourite Stations",
      meta: [
        {
          name: "description",
          content:
            "List of all your Stations you recently tagged as your favourite",
        },
      ],
    };
  },
  components: {
    heading,
  },
  async mounted() {
    await this.getStations();
  },
  computed: {
    rotateClass: function () {
      if (this.stations.loading) {
        return "rotate";
      }
      return "";
    },
  },
  data() {
    return {
      stations: {
        loading: true,
        headers: [
          {
            text: "Name",
            align: "center",
            sortable: true,
            value: "name",
          },
          {
            text: "Brand",
            align: "center",
            sortable: true,
            value: "brand",
          },
          {
            text: "Open",
            align: "center",
            sortable: true,
            value: "isOpen",
          },
          {
            text: "Super E5",
            align: "center",
            sortable: true,
            value: "e5",
          },
          {
            text: "Super E10",
            align: "center",
            sortable: true,
            value: "e10",
          },
          {
            text: "Diesel",
            align: "center",
            sortable: true,
            value: "diesel",
          },
        ],
        items: [],
      },
    };
  },
  methods: {
    customSort(items, index, isDesc) {
      let indexString = null;
      if (index.length > 0) {
        indexString = index[0];
      }
      let isDescBool = null;
      if (isDesc.length > 0) {
        isDescBool = isDesc[0];
      }

      if (indexString) {
        items = items.filter((item) => {
          return item[indexString] != null;
        });
        items.sort((a, b) => {
          if (!isDescBool) {
            return this.compare(a[indexString], b[indexString]);
          } else {
            return this.compare(b[indexString], a[indexString]);
          }
        });
      }
      return items;
    },
    compare: function (a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
    handleDetailView: function (row) {
      this.$router.push({ name: "StationDetail", params: { id: row.id } });
    },
    getStations: async function () {
      this.stations.loading = true;
      let response = await supabase.from("favouritestations").select();
      if (response.data.length > 0) {
        let stations = response.data;
        for (let i = 0; stations.length > i; i++) {
          let payload = {
            stationId: stations[i].station_id,
          };
          let response = await fetch(this.$apiUrl + "/station/detail", {
            method: "POST",
            body: JSON.stringify(payload),
          });

          let responseData = await response.json();
          this.stations.items.push({
            id: responseData.details.id,
            name: responseData.details.name,
            brand: responseData.details.brand,
            isOpen: responseData.details.isOpen,
            e5: responseData.details.e5,
            e10: responseData.details.e10,
            diesel: responseData.details.diesel,
          });
        }
      }
      this.stations.loading = false;
    },
  },
};
</script>
<style scoped>
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
