import Vue from "vue";
import VueRouter from "vue-router";

import { supabase } from "@/plugins/supabase/supabase.js";
import { getParameterByName } from "@/plugins/supabase/helpers";
import { getProfile } from "../plugins/router/helper";

import GasStations from "../views/GasStations.vue";
import StationDetail from "../views/StationDetail.vue";
import Tracker from "../views/Tracker.vue";
import ResetPassword from "../views/ResetPassword.vue";
import FirstLogin from "../views/FirstLogin.vue";
import Profile from "../views/Profile.vue";
import Calculator from "../views/Calculator.vue";
import Login from "../views/Login.vue";
import DevOverview from "../views/DevOverview.vue";
import ReportBug from "../views/ReportBug.vue";
import Home from "../views/Home.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Imprint from "../views/Imprint.vue";
import FavouriteStations from "../views/FavouriteStations.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    title: "Home",
    component: Home,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/gasstations",
    name: "GasStations",
    title: "Gas Stations",
    component: GasStations,
    meta: { authRequired: false, locationRequired: true },
  },
  {
    path: "/stationdetail/:id",
    name: "StationDetail",
    title: "Detail view of a Station",
    component: StationDetail,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/tracker",
    name: "Tracker",
    title: "Tracker",
    component: Tracker,
    meta: { authRequired: true, locationRequired: true },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    title: "Password Reset",
    component: ResetPassword,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/firstlogin",
    name: "FirstLogin",
    title: "First Login",
    component: FirstLogin,
    meta: { authRequired: true, locationRequired: false },
  },
  {
    path: "/profile",
    name: "Profile",
    title: "Profile",
    component: Profile,
    meta: { authRequired: true, locationRequired: false },
  },
  {
    path: "/calculator",
    name: "Calculator",
    title: "Calculator",
    component: Calculator,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/login",
    name: "Login",
    title: "Login",
    component: Login,
    meta: { authRequired: false, locationRequired: true },
  },
  {
    path: "/devoverview",
    name: "DevOverview",
    title: "Dev-Overview",
    component: DevOverview,
    meta: { authRequired: true, locationRequired: false },
  },
  {
    path: "/reportbug",
    name: "ReportBug",
    title: "Report a Bug",
    component: ReportBug,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    title: "Privacy Policy",
    component: PrivacyPolicy,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/imprint",
    name: "Imprint",
    title: "Imprint",
    component: Imprint,
    meta: { authRequired: false, locationRequired: false },
  },
  {
    path: "/favouritestations",
    name: "FavouriteStations",
    title: "Favourite Stations",
    component: FavouriteStations,
    meta: { authRequired: true, locationRequired: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let toUrl = location.origin + to.fullPath;
  const requestType = getParameterByName("type", toUrl);

  if (supabase.auth.session()) {
    Vue.prototype.$globalState.isLoggedIn = true;
  } else {
    Vue.prototype.$globalState.isLoggedIn = false;
  }

  if (to.meta.locationRequired) {
    Vue.prototype.$globalState.loadingLocation = true;
    if (
      !Vue.prototype.$globalState.searchParams.location.longitude ||
      !Vue.prototype.$globalState.searchParams.location.latitude
    ) {
      await Vue.prototype
        .$getLocation({ enableHighAccuracy: true })
        .then((coordinates) => {
          Vue.prototype.$globalState.searchParams.location = {
            longitude: coordinates.lng,
            latitude: coordinates.lat,
          };
        });
    }
    Vue.prototype.$globalState.loadingLocation = false;
  }

  if (requestType === "email_change") {
    const accessToken = getParameterByName("access_token", toUrl);
    supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      next({
        name: "Profile",
        query: { message: "E-Mail changed successfully!" },
      });
    } else {
      next({ name: "Tracker", query: { message: "Failed changing E-Mail" } });
    }
  } else if (requestType === "signup") {
    const accessToken = getParameterByName("access_token", toUrl);
    supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      let profilePayload = await getProfile();
      if (profilePayload.firstLogin) {
        next({
          name: "FirstLogin",
          query: {
            message: "Finish setting up your profile!",
          },
        });
      } else {
        next({
          name: "Tracker",
          query: { message: "Successfully confirmed Sign-Up!" },
        });
      }
    } else {
      next({ name: "Login", query: { message: "Failed confirming Sign-Up" } });
    }
  } else if (requestType === "recovery") {
    const accessToken = getParameterByName("access_token", toUrl);
    next({ name: "ResetPassword", query: { token: accessToken } });
  } else {
    if (to.meta.authRequired) {
      if (supabase.auth.session()) {
        if (to.name === "FirstLogin") {
          let profile = await getProfile();
          if (profile.firstLogin) {
            next();
          } else {
            next({
              name: "Tracker",
              query: { message: "You already finished your Sign-Up Process!" },
            });
          }
        } else {
          let profile = await getProfile();
          if (profile.firstLogin) {
            next({
              name: "FirstLogin",
              query: {
                message: "Finish setting up your profile!",
              },
            });
          } else {
            if (to.name === "DevOverview") {
              if (Vue.prototype.$globalState.profile.is_dev) {
                next();
              } else {
                next({
                  name: "Tracker",
                  query: { message: "You aren't a dev!" },
                });
              }
            } else if (to.name === "FirstLogin") {
              next({
                name: "Tracker",
                query: {
                  message: "You already finished your Sign-Up Process!",
                },
              });
            } else {
              next();
            }
          }
        }
      } else {
        next({ name: "Login", query: { redirect: to.name } });
      }
    } else {
      if (to.name === "Login") {
        if (supabase.auth.session()) {
          next({ name: "Tracker" });
        } else {
          next();
        }
      } else if (to.name === "ResetPassword") {
        if (to.query && to.query.token) {
          next();
        } else if (supabase.auth.session()) {
          next({
            name: "Tracker",
            query: {
              message:
                "You cannot access this site without a Password-Reset-Token!",
            },
          });
        } else {
          next({
            name: "Login",
            query: {
              message:
                "You cannot access this site without a Password-Reset-Token!",
            },
          });
        }
      } else {
        next();
      }
    }
  }
});

export default router;
