var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-3"},[(!_vm.detailLoading)?_c('div',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-overline font-weight-bold"},[_vm._v(" "+_vm._s(_vm.station.brand)+" ")])])],1),_c('v-row',{staticClass:"mt-n8"},[_c('v-col',[_c('span',{staticClass:"\n            text-md-h3 text-sm-h4 text-h5\n            secondary--text\n            font-weight-bold\n          "},[_vm._v(" "+_vm._s(_vm.station.name)+" ")])])],1),_c('v-row',{staticClass:"mt-n6"},[_c('v-col',[_c('a',{class:_vm.urlClass,staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"href":_vm.mapUrl,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.station.street)+" "+_vm._s(_vm.station.houseNumber)+", "+_vm._s(_vm.station.postCode)+" "+_vm._s(_vm.station.place))])])],1),_c('v-row',{staticClass:"mt-n3"},[_c('v-col',[_c('span',[(_vm.station.isOpen)?_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-clock-check-outline")])]}}],null,false,379419914)},[_c('span',[_vm._v("Currently opened")])]):_c('v-tooltip',{attrs:{"right":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-clock-remove-outline")])]}}],null,false,1766433928)},[_c('span',[_vm._v("Currently closed")])]),(_vm.station.wholeDay)?_c('v-tooltip',{attrs:{"right":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"px-2",attrs:{"color":"secondary"}},'v-icon',attrs,false),on),[_vm._v("mdi-hours-24")])]}}],null,false,3026643964)},[_c('span',[_vm._v("Open 24/7")])]):_vm._e(),(_vm.$globalState.isLoggedIn && !_vm.isFavourite)?_c('v-tooltip',{attrs:{"right":"","color":"grey"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.changeFavouriteState}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"px-2",attrs:{"color":"grey"}},[_vm._v("mdi-star-outline")])],1)]}}],null,false,3973249094)},[_c('span',[_vm._v("Add to Favourites")])]):_vm._e(),(_vm.$globalState.isLoggedIn && _vm.isFavourite)?_c('v-tooltip',{attrs:{"right":"","color":"yellow"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.changeFavouriteState}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"px-2",attrs:{"color":"yellow"}},[_vm._v("mdi-star")])],1)]}}],null,false,3986251558)},[_c('span',{staticClass:"black--text"},[_vm._v("Remove from Favourites")])]):_vm._e()],1)])],1),_c('div',{staticClass:"hidden-sm-and-up"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"color":"primary","height":"100%","elevation":"10"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"\n                  text-md-h6 text-subtitle-1\n                  font-weight-bold\n                  secondary--text\n                "},[_vm._v("Current Prices")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getPrice}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.priceTable.headersMobile,"items":_vm.priceTable.itemsMobile,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","loading":_vm.priceTable.loading},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.price || " - ")+" ")])]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"color":"primary","height":"100%","elevation":"10"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"\n                  text-md-h6 text-subtitle-1\n                  font-weight-bold\n                  secondary--text\n                "},[_vm._v("Opening Times")])]),(_vm.station.openingTimes.length > 0)?_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.openingTimesHeaders,"items":_vm.station.openingTimes,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","loading":_vm.detailLoading}}):_c('div',{attrs:{"id":"outer"}},[_c('div',{staticClass:"d-flex justify-center align-center text-center",attrs:{"id":"inner_remaining"}},[_c('v-container',{staticClass:"pb-6"},[_c('span',[_vm._v("There are no Opening Times given by "+_vm._s(_vm.station.name)+"! ")])])],1)])],1)],1)],1)],1),_c('v-row',{staticClass:"hidden-xs-only"},[_c('v-col',[_c('v-card',{attrs:{"color":"primary","height":"100%","elevation":"10"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"\n                text-md-h6 text-subtitle-1\n                font-weight-bold\n                secondary--text\n              "},[_vm._v("Current Prices")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getPrice}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.priceTable.headers,"items":_vm.priceTable.items,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","loading":_vm.priceTable.loading},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.price || " - ")+" ")])]}}],null,true)})],1)],1),_c('v-col',[_c('v-card',{attrs:{"color":"primary","height":"100%","elevation":"10"}},[_c('v-card-title',{staticClass:"pb-0"},[_c('span',{staticClass:"\n                text-md-h6 text-subtitle-1\n                font-weight-bold\n                secondary--text\n              "},[_vm._v("Opening Times")])]),(_vm.station.openingTimes.length > 0)?_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.openingTimesHeaders,"items":_vm.station.openingTimes,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","loading":_vm.detailLoading}}):_c('div',{attrs:{"id":"outer"}},[_c('div',{staticClass:"d-flex justify-center align-center text-center",attrs:{"id":"inner_remaining"}},[_c('span',[_vm._v("There are no Opening Times given by "+_vm._s(_vm.station.name)+"! ")])])])],1)],1)],1),(this.$globalState.isLoggedIn)?_c('v-row',[_c('v-col',[_c('refuelHistory',{attrs:{"refuelActionItems":_vm.refuelActionItems,"elevation":10,"type":"Station","cardTitle":'Refuel History for ' + _vm.station.name + ' - ',"noDataMessage":'Will be displayed as soon as you have refueled at least one time at ' +
            _vm.station.name +
            '!'},on:{"update:refuelActionItems":function($event){_vm.refuelActionItems=$event},"update:refuel-action-items":function($event){_vm.refuelActionItems=$event}}})],1)],1):_vm._e()],1):_c('div',{staticClass:"center",staticStyle:{"height":"88vh"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('heading',{attrs:{"text":"Pending Location Grant"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"3"}}),_c('v-col',{staticClass:"text-center text-overline",attrs:{"md":"6"}},[_vm._v(" Please grant "+_vm._s(_vm.$applicationName)+" to locate your Position. We need your position to search for gas-stations in your close surroundings. Your location won't be submitted to any third party. ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }