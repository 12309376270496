<template>
  <v-card color="primary" height="100%" :elevation="elevation">
    <v-container>
      <v-row>
        <v-col>
          <span
            class="text-md-h6 text-subtitle-1 font-weight-bold secondary--text"
            >{{ cardTitle }}
          </span>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn icon @click="editCar.editActive = !editCar.editActive">
            <v-icon color="secondary">mdi-pencil</v-icon>
          </v-btn>
          <v-btn color="error" @click="pendingDeleteConfirmation = true" icon
            ><v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-form v-model="editCar.validChanges">
        <v-row>
          <v-col class="font-weight-bold d-flex align-center"> Brand </v-col>
          <v-col v-if="editCar.editActive">
            <v-text-field
              label="Brand"
              v-model="editCar.editedCar.brand"
              :placeholder="selectedCar.brand"
              color="secondary"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-col>
          <v-col v-else>
            {{ selectedCar.brand }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold d-flex align-center"> Model </v-col>
          <v-col v-if="editCar.editActive">
            <v-text-field
              label="Model"
              v-model="editCar.editedCar.model"
              :placeholder="selectedCar.model"
              color="secondary"
              :rules="[notEmpty]"
            ></v-text-field>
          </v-col>
          <v-col v-else>
            {{ selectedCar.model }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold d-flex align-center">
            Fuel Type
          </v-col>
          <v-col v-if="editCar.editActive">
            <v-select
              color="secondary"
              item-color="secondary"
              :placeholder="getFuelName(selectedCar.fuel_type)"
              label="Fuel Type"
              :items="fuelTypes"
              return-object
              item-text="name"
              v-model="editCar.editedCar.fuelType"
            ></v-select>
          </v-col>
          <v-col v-else>
            {{ getFuelName(selectedCar.fuel_type) }}
          </v-col>
        </v-row>

        <v-row>
          <v-col class="font-weight-bold"> Last Mileage </v-col>
          <v-col> {{ selectedCar.mileage | number(2) }} km </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold"> Average Consumption </v-col>
          <v-col v-if="selectedCar.consumption === 0"> N/A </v-col>
          <v-col v-else>
            {{ selectedCar.consumption | number(2) }}
            l /100km
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold"> Costs so Far </v-col>
          <v-col> {{ selectedCar.costs | number(2) }} € </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold"> Times Refueld </v-col>
          <v-col>
            {{ selectedCar.times_refueled }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold"> Last Update </v-col>
          <v-col>
            {{ createDate(selectedCar.updated) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="font-weight-bold"> Created </v-col>
          <v-col>
            {{ createDate(selectedCar.created) }}
          </v-col>
        </v-row>
      </v-form>
      <v-row v-if="editCar.editActive">
        <v-col class="d-flex justify-end">
          <v-btn color="background" @click="editCar.editActive = false"
            >Cancel</v-btn
          >
        </v-col>
        <v-col class="d-flex justify-start">
          <v-btn
            color="secondary"
            @click="updateCar"
            :disabled="!newCarInfoChanges || !editCar.validChanges"
            :loading="editCar.loadingSave"
            >Save Changes</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <v-overlay v-model="pendingDeleteConfirmation">
      <v-card
        color="background"
        outlined
        class="overlay"
        :light="!$vuetify.theme.dark"
      >
        <v-container v-if="selectedCar">
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >Delete Car</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                >Are you sure you want to delete {{ selectedCar.brand }}
                {{ selectedCar.model }}?
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="pendingDeleteConfirmation = !pendingDeleteConfirmation"
                color="background"
                class="mr-3 cancel text--text"
                text
                >Cancel</v-btn
              >
              <v-btn @click="deleteCar" color="error" text class="delete"
                >Yes</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
  </v-card>
</template>
<script>
// EMITS:
// update:selectedCar -> new car object from supabase is passed
// getUserCars -> no data is passed

import { supabase } from "@/plugins/supabase/supabase.js";
import { createDate } from "@/plugins/vuetify/helper.js";
export default {
  name: "carInfo",
  props: {
    selectedCar: Object,
    elevation: Number,
    cardTitle: String,
  },
  data() {
    return {
      pendingDeleteConfirmation: false,
      editCar: {
        editActive: false,
        validChanges: false,
        loadingSave: false,
        editedCar: {
          brand: "",
          model: "",
          fuelType: "",
        },
      },
      fuelTypes: [
        {
          name: "Super E10",
          value: "e10",
        },
        {
          name: "Super E5",
          value: "e5",
        },
        {
          name: "Diesel",
          value: "diesel",
        },
      ],
    };
  },
  watch: {
    selectedCar: async function () {
      this.resetEditCar(this.selectedCar);
    },
  },
  mounted() {
    this.resetEditCar(this.selectedCar);
  },
  computed: {
    newCarInfoChanges: function () {
      if (
        this.selectedCar.brand != this.editCar.editedCar.brand ||
        this.selectedCar.model != this.editCar.editedCar.model ||
        this.selectedCar.fuel_type != this.editCar.editedCar.fuelType.value
      ) {
        if (
          this.editCar.editedCar.brand &&
          this.editCar.editedCar.model &&
          this.editCar.editedCar.fuelType.value
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    getFuelName: function (fuelType) {
      return this.fuelTypes.filter((type) => {
        return type.value === fuelType;
      })[0].name;
    },
    createDate: function (epoch) {
      return createDate(epoch);
    },
    resetEditCar: function (car) {
      this.editProfile = false;
      this.editCar.editedCar.brand = car.brand;
      this.editCar.editedCar.model = car.model;
      this.editCar.editedCar.fuelType = {
        name: this.getFuelName(car.fuel_type),
        value: car.fuel_type,
      };
    },
    updateCar: async function () {
      this.editCar.loadingSave = true;
      let response = await supabase
        .from("cars")
        .update({
          display_name:
            this.editCar.editedCar.brand + " " + this.editCar.editedCar.model,
          brand: this.editCar.editedCar.brand,
          model: this.editCar.editedCar.model,
          fuel_type: this.editCar.editedCar.fuelType.value,
        })
        .match({ id: this.selectedCar.id });

      if (response.data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Change Car Info",
          message: "Successfully saved Car Changes!",
        };
        this.$globalState.addNotification(notify);
        this.$emit("update:selectedCar", response.data[0]);
        this.$emit("getUserCars");
        this.resetEditCar(response.data[0]);
        this.editCar.editActive = false;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Change Car Info",
          message: "Failed saving Car Changes! Please try again later!",
        };
        this.$globalState.addNotification(notify);
      }
      this.editCar.loadingSave = false;
    },
    deleteCar: async function () {
      this.pendingDeleteConfirmation = false;
      let response = await supabase
        .from("cars")
        .delete()
        .match({ id: this.selectedCar.id });

      if (response.data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline:
            "Successfully deleted " +
            this.selectedCar.display_name +
            " from your Garage",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.$emit("update:selectedCar", null);
        this.$emit("update:refuelActionItems", []);
        this.$emit("getUserCars");
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Car from Garage",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Car from Garage",
          message: "Failed deleting Car! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
  },
};
</script>
<style scoped>
.theme--dark.v-list {
  background: var(--v-primary-base);
}
.overlay {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.cancel {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.delete {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-error-base);
}
</style>
