import Vue from "vue";
import { supabase } from "@/plugins/supabase/supabase.js";

async function getProfile() {
  let responsePayload = {
    state: false,
    firstLogin: false,
    msg: "",
    profile: null,
  };
  let response = await supabase.from("profiles").select();

  if (response.error === null) {
    if (response.data.length > 0) {
      let data = response.data[0];
      Vue.prototype.$globalState.profile = data;
      if (data.name != null) {
        responsePayload.state = true;
        responsePayload.msg = "Fetched profile!";
        responsePayload.profile = data;
      } else {
        responsePayload.msg = "First login!";
        responsePayload.firstLogin = true;
        responsePayload.profile = data;
      }
    } else {
      responsePayload.msg = "No data found!";
    }
  } else {
    responsePayload.msg = response.error;
  }

  return responsePayload;
}

export { getProfile };
