<template>
  <v-card color="primary" class="wrapper">
    <v-list color="primary">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="secondary--text">
            {{ profile.firstName || "Your" }} {{ profile.lastName || "Name" }}
          </v-list-item-title>
          <v-list-item-subtitle
            >Cars in Garage: {{ profile.amountCars }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list color="primary">
      <v-list-item
        to="Profile"
        @click="closeMenu"
        active-class="primary--text font-weight-bold"
      >
        <v-list-item-avatar>
          <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>

        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>

      <v-list-item
        to="tracker"
        @click="closeMenu"
        active-class="primary--text font-weight-bold"
      >
        <v-list-item-avatar>
          <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>Tracker</v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="handleLogout"
        active-class="primary--text font-weight-bold"
      >
        <v-list-item-avatar>
          <v-icon color="error">mdi-logout</v-icon>
        </v-list-item-avatar>
        <v-list-item-title class="error--text">Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "profileMenuCard",
  data() {
    return {
      profile: {
        firstName: "",
        lastName: "",
        amountCars: 0,
      },
    };
  },
  async mounted() {
    await this.getUserProfile();
  },
  methods: {
    closeMenu: function () {
      this.$emit("update:menu", false);
    },
    handleLogout: async function () {
      this.closeMenu();
      await supabase.auth.signOut();
      this.$globalState.isLoggedIn = false;
      if (this.$route.meta.authRequired) {
        this.$router.push({ name: "GasStations" });
      }
      this.closeMenu();
    },
    getUserProfile: async function () {
      let response = await supabase.from("profiles").select();
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Profile",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else if (response.data.length > 0) {
        this.profile.firstName = response.data[0].name;
        this.profile.lastName = response.data[0].last_name;

        response = await supabase
          .from("cars")
          .select("id", { count: "exact", head: true });

        if (!isNaN(response.count)) {
          this.profile.amountCars = response.count;
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Fetching Profile",
            message: "Failed counting your Cars! Try again later!",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Profile",
          message: "Failed fetching Profile! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
  },
};
</script>
<style scoped>
.wrapper {
  overflow: hidden;
  max-width: 390px;
  min-width: 370px;
}
.v-list-item--active {
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
</style>
