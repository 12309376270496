export default {
  primary: {
    base: "#e8e7ed",
  },
  secondary: {
    base: "#13d389",
    lighten1: "#2bd996",
    lighten2: "#45e6a8",
    lighten3: "#69f5bf",
    darken1: "#11a66c",
    darken2: "#108f5e",
    darken3: "#0d784f",
  },
  error: {
    base: "#B00020",
  },
  warning: {
    base: "#FFC400",
  },
  info: {
    base: "#03A9F4",
  },
  success: {
    base: "#009649",
  },
  pending: {
    base: "#929292",
  },
  background: {
    base: "#f5f5fb",
  },
  text: {
    base: "#000000",
  },
  theme: {
    base: "#000",
  },
};
