<template>
  <div class="spacer layered-waves-bottom center">
    <v-app-bar fixed color="transparent" elevation="0">
      <v-row>
        <v-col>
          <fuelTrackerName :height="40" :width="230" />
        </v-col>
        <div v-if="!this.$globalState.isLoggedIn">
          <v-col
            v-if="$vuetify.breakpoint.width > 520"
            class="d-flex justify-end"
          >
            <v-btn color="transparent" elevation="0" class="mr-4" to="login"
              >Login</v-btn
            >
            <v-btn
              class="font-weight-bold"
              color="secondary"
              @click="
                $router.push({ name: 'Login', query: { register: true } })
              "
            >
              Get Started
            </v-btn>
          </v-col>
          <v-col v-else class="d-flex justify-end">
            <v-btn icon to="login">
              <v-icon> mdi-login </v-icon>
            </v-btn>
          </v-col>
        </div>
        <v-col v-else class="d-flex justify-end">
          <v-btn class="mr-1" icon @click="$router.push({ name: 'Profile' })">
            <v-icon large>mdi-account</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <div
      :style="
        $vuetify.breakpoint.width > 520
          ? 'margin-top: -10%'
          : 'margin-top: -60%'
      "
    >
      <v-row>
        <v-col class="d-flex justify-center align-center" style="height: 30vh">
          <fuelTrackerName :width="nameWidth" :height="270" />
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.width > 820">
        <v-col class="d-flex justify-center">
          <span :class="sloganClass"
            >Find the cheapest Gas-Station in your Surrounding - Keep track of
            your Fuel Consumption with ease</span
          >
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.width > 520">
        <v-col class="d-flex justify-end">
          <v-btn color="secondary" to="gasstations">Find GasStation</v-btn>
        </v-col>
        <v-col class="d-flex justify-start">
          <v-btn class="text--text" color="primary" to="tracker"
            >Track Consumption</v-btn
          >
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col class="d-flex justify-center mt-n16">
            <v-btn color="secondary" to="gasstations">Find GasStation</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn class="text--text" color="primary" to="tracker"
              >Track Consumption</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import fuelTrackerName from "../components/fuelTrackerName.vue";
export default {
  name: "Home",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Home",
      meta: [
        {
          name: "description",
          content:
            this.$applicationName +
            " provides an overview of Gas-Stations in your surrounding and lets you not only track your fuel consumption and many more statistics about your refueling behavior, but also displays them in a detailed chart or table. Happy Tracking!",
        },
      ],
    };
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        setTimeout(() => {
          this.$vuetify.theme.dark = false;
        }, 200);
      }
    }
  },
  components: {
    fuelTrackerName,
  },
  computed: {
    nameWidth: function () {
      if (this.$vuetify.breakpoint.width > 1530) {
        return 1500;
      } else if (
        this.$vuetify.breakpoint.width < 1530 &&
        this.$vuetify.breakpoint.width > 1230
      ) {
        return 1200;
      } else if (
        this.$vuetify.breakpoint.width < 1230 &&
        this.$vuetify.breakpoint.width > 980
      ) {
        return 950;
      } else if (
        this.$vuetify.breakpoint.width < 980 &&
        this.$vuetify.breakpoint.width > 820
      ) {
        return 750;
      } else if (
        this.$vuetify.breakpoint.width < 820 &&
        this.$vuetify.breakpoint.width > 580
      ) {
        return 520;
      } else if (
        this.$vuetify.breakpoint.width < 580 &&
        this.$vuetify.breakpoint.width > 440
      ) {
        return 400;
      } else if (
        this.$vuetify.breakpoint.width < 440 &&
        this.$vuetify.breakpoint.width > 310
      ) {
        return 280;
      } else if (
        this.$vuetify.breakpoint.width < 310 &&
        this.$vuetify.breakpoint.width > 210
      ) {
        return 180;
      }
      return 0;
    },
    sloganClass: function () {
      if (this.$vuetify.breakpoint.width > 1530) {
        return "secondary--text font-weight-bold text-uppercase mt-n10 text-h5";
      } else if (
        this.$vuetify.breakpoint.width < 1530 &&
        this.$vuetify.breakpoint.width > 1230
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10 text-h6";
      } else if (
        this.$vuetify.breakpoint.width < 1230 &&
        this.$vuetify.breakpoint.width > 980
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10 text-subtitle-1";
      } else if (
        this.$vuetify.breakpoint.width < 980 &&
        this.$vuetify.breakpoint.width > 780
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10 text-subtitle-2";
      } else if (
        this.$vuetify.breakpoint.width < 780 &&
        this.$vuetify.breakpoint.width > 580
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10";
      } else if (
        this.$vuetify.breakpoint.width < 580 &&
        this.$vuetify.breakpoint.width > 440
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10";
      } else if (
        this.$vuetify.breakpoint.width < 440 &&
        this.$vuetify.breakpoint.width > 310
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10";
      } else if (
        this.$vuetify.breakpoint.width < 310 &&
        this.$vuetify.breakpoint.width > 210
      ) {
        return "secondary--text font-weight-bold text-uppercase mt-n10";
      }
      return "secondary--text font-weight-bold text-uppercase mt-n10";
    },
  },
};
</script>
<style scoped>
.center {
  display: grid;
  place-items: center;
}
/* .spacer {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
} */

.layered-waves-bottom {
  background-image: url("../assets/layered-waves.svg");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
