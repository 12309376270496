<template>
  <div class="wrapper" id="authForm">
    <div class="title-text">
      <div class="title login font-weight-bold text-h4">Login</div>
      <div class="title signup font-weight-bold text-h4">Signup</div>
    </div>
    <div class="form-container">
      <div class="slide-controls">
        <input type="radio" name="slide" id="login" checked />
        <input type="radio" name="slide" id="signup" />
        <label for="login" class="slide login">Login</label>
        <label for="signup" class="slide signup">Signup</label>
        <div class="slider-tab"></div>
      </div>
      <div class="form-inner">
        <form action="#" class="login">
          <v-form style="width: 100%" v-model="login.valid">
            <div class="field">
              <v-text-field
                class="mb-4"
                color="secondary"
                outlined
                label="E-Mail"
                v-model="login.user.email"
                :rules="[notEmpty, validEmail]"
                type="email"
              ></v-text-field>
            </div>
            <div class="field">
              <v-text-field
                outlined
                color="secondary"
                label="Password"
                v-model="login.user.password"
                :rules="[notEmpty]"
                type="password"
                @keyup.enter="loginUser"
              ></v-text-field>
            </div>
          </v-form>
          <div class="pass-link mt-5">
            <a @click="handlePasswordReset" class="secondary--text"
              >Forgot your Password?</a
            >
          </div>
          <div class="px-2">
            <v-btn
              class="field btn primary--text font-weight-bold"
              :loading="login.loading"
              :disabled="!login.valid"
              @click="loginUser"
              >Login</v-btn
            >
          </div>
          <div class="mt-4">
            <v-row>
              <v-col class="d-flex justify-center align-center pb-2 pr-0">
                <v-divider></v-divider>
              </v-col>
              <v-col
                :cols="dividerCol"
                class="
                  d-flex
                  justify-center
                  align-center
                  pb-2
                  font-weight-bold
                  px-0
                "
              >
                <span>Or Sign In With</span>
              </v-col>
              <v-col class="d-flex justify-center align-center pb-2 pl-0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center pt-0">
                <v-btn icon @click="handleProviderLogin('google')">
                  <v-icon>mdi-google</v-icon>
                </v-btn>
              </v-col>
              <v-col class="d-flex justify-center pt-0">
                <v-btn icon @click="handleProviderLogin('discord')">
                  <v-icon>mdi-discord</v-icon>
                </v-btn>
              </v-col>
              <v-col class="d-flex justify-center pt-0">
                <v-btn icon @click="handleProviderLogin('spotify')">
                  <v-icon>mdi-spotify</v-icon>
                </v-btn>
              </v-col>
              <v-col class="d-flex justify-center pt-0">
                <v-btn icon class="email-signup">
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </form>
        <form action="#" class="signup">
          <v-form style="width: 100%" v-model="signUp.valid">
            <div class="field">
              <v-text-field
                outlined
                color="secondary"
                label="E-Mail"
                type="email"
                v-model="signUp.user.email"
                :rules="[notEmpty, validEmail]"
              ></v-text-field>
            </div>
            <div class="field">
              <v-text-field
                outlined
                color="secondary"
                label="Password"
                v-model="signUp.user.password"
                :rules="[notEmpty, minPasswordRequirements]"
                type="password"
              ></v-text-field>
            </div>
            <div class="field">
              <v-text-field
                outlined
                color="secondary"
                label="Password"
                v-model="signUp.user.password2"
                :rules="[notEmpty, minPasswordRequirements, samePassword]"
                type="password"
                @keyup.enter="signUpUser"
              ></v-text-field>
            </div>
          </v-form>
          <div class="px-2">
            <v-btn
              class="field btn primary--text font-weight-bold"
              @click="signUpUser"
              :loading="signUp.loading"
              :disabled="!signUp.valid"
              >Signup</v-btn
            >
          </div>
          <div class="login-link mt-5 text-center">
            Already have an Account? <a class="secondary--text">Login now</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { getProfile } from "@/plugins/router/helper.js";
export default {
  name: "authForm",
  data() {
    return {
      login: {
        loading: false,
        valid: false,
        user: {
          email: "",
          password: "",
        },
      },
      signUp: {
        loading: false,
        valid: false,
        user: {
          email: "",
          password: "",
          password2: "",
        },
      },
    };
  },
  computed: {
    dividerCol: function () {
      if (this.$vuetify.breakpoint.width > 400) {
        return 5;
      } else if (
        this.$vuetify.breakpoint.width < 400 &&
        this.$vuetify.breakpoint.width > 330
      ) {
        return 6;
      } else if (
        this.$vuetify.breakpoint.width < 330 &&
        this.$vuetify.breakpoint.width > 300
      ) {
        return 7;
      } else {
        return 8;
      }
    },
  },
  mounted() {
    const loginText = document.querySelector(".title-text .login");
    const loginForm = document.querySelector("form.login");
    const loginBtn = document.querySelector("label.login");
    const signupBtn = document.querySelector("label.signup");
    const signupLink = document.querySelector("form .login-link a");
    const mailBtn = document.querySelector(".email-signup");
    signupBtn.onclick = () => {
      loginForm.style.marginLeft = "-50%";
      loginText.style.marginLeft = "-50%";
    };
    loginBtn.onclick = () => {
      loginForm.style.marginLeft = "0%";
      loginText.style.marginLeft = "0%";
    };
    signupLink.onclick = () => {
      loginBtn.click();
      return false;
    };
    mailBtn.onclick = () => {
      signupBtn.click();
      return false;
    };
    loginBtn.click();
    if (this.$route.query.register) {
      signupBtn.click();
    }
  },
  methods: {
    closeMenu: function () {
      this.$emit("update:menu", false);
    },
    signUpUser: async function () {
      this.signUp.loading = true;
      let user = null;
      if (this.$dev) {
        user = await supabase.auth.signUp(
          {
            email: this.signUp.user.email,
            password: this.signUp.user.password,
          },
          {
            redirectTo: this.$siteUrl,
          }
        );
      } else {
        user = await supabase.auth.signUp({
          email: this.signUp.user.email,
          password: this.signUp.user.password,
        });
      }

      if (user) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Sign-Up",
          message: "Confirmation-Email Sent! Check your Inbox!",
        };
        this.$globalState.addNotification(notify);
        this.closeMenu();
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Sign-Up",
          message: "Failed sending Confirmation-Email!",
        };
        this.$globalState.addNotification(notify);
      }
      this.signUp.loading = false;
    },
    loginUser: async function () {
      this.login.loading = true;
      const { user, error } = await supabase.auth.signIn({
        email: this.login.user.email,
        password: this.login.user.password,
      });
      if (user && user.id) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Log-In",
          message: "Successfully Logged In!",
        };
        this.$globalState.addNotification(notify);
        await getProfile();
        if (this.$route.query.redirect) {
          this.$router.push({ name: this.$route.query.redirect });
        } else if (this.$route.name === "Login") {
          this.$router.push({ name: "Tracker" });
        }

        this.$globalState.isLoggedIn = true;

        this.closeMenu();
      } else if (error.status === 400) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Log-In",
          message: error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Log-In",
          message: "Failed logging in!",
        };
        this.$globalState.addNotification(notify);
      }
      this.login.loading = false;
    },
    handlePasswordReset: async function () {
      if (
        this.login.user.email &&
        this.validEmail(this.login.user.email) === true
      ) {
        const { error } = await supabase.auth.api.resetPasswordForEmail(
          this.login.user.email
        );
        if (!error) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Reset Password",
            message:
              "We sent a link with a Password-Reset-Link to the E-Mail-Address " +
              this.login.user.email,
          };
          this.$globalState.addNotification(notify);
          this.closeMenu();
        } else if (error.status === 404) {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Reset Password",
            message:
              "There's no user with the E-Mail-Address " +
              this.login.user.email,
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Reset Password",
          message: "Set a proper E-Mail!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    handleProviderLogin: async function (provider) {
      if (this.$route.query.redirect) {
        localStorage.setItem("redirect", this.$route.query.redirect);
      } else if (this.$route.name != "Login") {
        localStorage.setItem("redirect", this.$route.name);
      } else {
        localStorage.setItem("redirect", "Tracker");
      }
      if (this.$dev) {
        await supabase.auth.signIn(
          {
            provider: provider,
          },
          {
            redirectTo: this.$siteUrl,
          }
        );
      } else {
        await supabase.auth.signIn({
          provider: provider,
        });
      }
    },

    //RULES
    notEmpty: function (input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    validEmail: function (input) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(input).toLowerCase())) {
        return true;
      }
      return "Invalid E-Mail";
    },
    minPasswordRequirements: function (input) {
      let reUpperCase = /^(?=.*[A-Z])/;
      let reLowerCase = /^(?=.*[a-z])/;
      let reNumber = /^(?=.*\d)/;
      let reSpecial = /^(?=.*[-+_!@#$%^&*.,?])/;
      let reLength = /^.{8,}$/;
      let upperCase = reUpperCase.test(input);
      let lowerCase = reLowerCase.test(input);
      let number = reNumber.test(input);
      let special = reSpecial.test(input);
      let length = reLength.test(input);

      if (upperCase && lowerCase && number && special && length) {
        return true;
      } else {
        let errorString = "Minimum Requirements: ";

        if (!upperCase) {
          errorString = errorString + "1 Uppercase, ";
        }
        if (!lowerCase) {
          errorString = errorString + "1 Lowercase, ";
        }
        if (!number) {
          errorString = errorString + "1 Number, ";
        }
        if (!special) {
          errorString = errorString + "1 Special Character (-+_!@#$%^&*.,?), ";
        }
        if (!length) {
          errorString = errorString + "8 Characters in Total";
        }
        if (errorString.slice(-2) === ", ") {
          errorString = errorString.slice(0, -2);
        }
        return errorString;
      }
    },
    samePassword: function (input) {
      if (input === this.signUp.user.password) {
        return true;
      }
      return "Passwords don't match!";
    },
  },
};
</script>
<style scoped>
::selection {
  background: #1a75ff;
  color: #fff;
}
.wrapper {
  overflow: hidden;
  max-width: 390px;
  min-width: 370px;
  background: var(--v-primary-base);
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.wrapper .title-text {
  color: var(--v-text-base);
  display: flex;
  width: 200%;
}
.wrapper .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.wrapper .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}
.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}
.slide-controls label.signup {
  color: var();
}
.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
input[type="radio"] {
  display: none;
}
#signup:checked ~ .slider-tab {
  left: 50%;
}
#signup:checked ~ label.signup {
  cursor: default;
  user-select: none;
  color: var(--v-theme-base);
}
#signup:checked ~ label.login {
  color: var(--v-secondary-base);
}
#login:checked ~ label.signup {
  color: var(--v-secondary-base);
}
#login:checked ~ label.login {
  cursor: default;
  user-select: none;
  color: var(--v-theme-base);
}

.v-btn {
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
}
.v-btn--disabled {
  opacity: 50%;
}
.v-btn--icon {
  background: transparent;
}

.wrapper .form-container {
  width: 100%;
  overflow: hidden;
}
.form-container .form-inner {
  display: flex;
  width: 200%;
}
.form-container .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.form-inner form .field {
  height: 82px;
  width: 100%;
  margin-top: 20px;
}
.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}
.form-inner form .field input:focus {
  border-color: #1a75ff;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}
.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}
form .field input:focus::placeholder {
  color: #1a75ff;
}
.form-inner form .pass-link {
  margin-top: 5px;
}
.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
  color: var(--v-text-base);
}
.form-inner form .pass-link a,
.form-inner form .login-link a {
  text-decoration: none;
}
.form-inner form .pass-link a:hover,
.form-inner form .login-link a:hover {
  text-decoration: underline;
}
.btn {
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}
form .btn .btn-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
  border-radius: 15px;
  transition: all 0.4s ease;
}
form .btn:hover .btn-layer {
  left: 0;
}
form .btn input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
</style>
