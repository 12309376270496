<template>
  <v-row>
    <v-col class="py-8">
      <span
        class="
          d-flex
          justify-center
          text-lg-h3 text-sm-h4 text-h5
          font-weight-bold
          secondary--text
        "
      >
        {{ text }}
      </span>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "heading",
  props: {
    text: String,
  },
};
</script>
<style scoped></style>
