<template>
  <v-card color="primary" :elevation="elevation">
    <v-card-title>
      <v-row>
        <v-col class="text-md-h6 font-weight-bold text-subtitle-1">
          <span v-if="cardTitle" class="secondary--text">{{ cardTitle }}</span>
          <span
            v-if="refuelActions.displayType === 'graph'"
            class="secondary--text"
            >Graph</span
          >
          <span v-else class="secondary--text">Table</span>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn
            icon
            @click="refuelActions.displayType = 'table'"
            :disabled="!refuelActionItems.length > 0"
          >
            <v-icon color="secondary"> mdi-table </v-icon>
          </v-btn>
          <v-btn
            icon
            @click="refuelActions.displayType = 'graph'"
            :disabled="!refuelActionItems.length > 0"
          >
            <v-icon color="secondary"> mdi-chart-line-stacked </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <div v-if="refuelActionItems.length > 0">
      <v-data-table
        v-if="refuelActions.displayType === 'table'"
        :headers="refuelActions.headers"
        :items="refuelActionItems"
        class="primary"
      >
        <template v-slot:[`item.litres`]="{ item }">
          <span class="ml-n2"> {{ item.litres | number(2) }} </span>
        </template>
        <template v-slot:[`item.trip`]="{ item }">
          <span class="ml-n2"> {{ item.trip }} km </span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <span class="ml-n2">
            {{ createDate(item.created) }}
          </span>
        </template>
        <template v-slot:[`item.fuel_type`]="{ item }">
          <span class="ml-n2">
            {{ getFuelName(item.fuel_type) }}
          </span>
        </template>
        <template v-slot:[`item.trip_consumption`]="{ item }">
          <span class="ml-n2">
            {{ item.trip_consumption | number(2) }}
            l/100km
          </span>
        </template>
        <template v-slot:[`item.price_per_litre`]="{ item }">
          <span class="ml-n2">
            {{ item.price_per_litre | number(2) }}
            €
          </span>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span class="ml-n2">
            {{ item.price | number(2) }}
            €
          </span>
        </template>
        <template v-slot:[`item.station_id`]="{ item }">
          <v-tooltip bottom :color="item.station_id ? 'secondary' : ''">
            <template v-slot:activator="{ on, attrs }">
              <span class="ml-n2" v-bind="attrs" v-on="on">
                <v-btn
                  icon
                  @click="handleDetailView(item.station_id)"
                  :disabled="!item.station_id"
                >
                  <v-icon color="secondary">mdi-gas-station</v-icon></v-btn
                >
              </span>
            </template>
            <span v-if="item.station_id"
              >Click to view Gas-Station-Details</span
            >
            <span v-else>No Gas-Station given</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.car_id`]="{ item }">
          <span class="ml-n2">
            <v-btn icon @click="handleCarView(item.car_id)">
              <v-icon color="secondary">mdi-car</v-icon>
            </v-btn>
          </span>
        </template>
        <template v-slot:[`item.merged_stash`]="{ item }">
          <v-tooltip bottom color="info">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon v-if="item.merged_stash" color="info">mdi-merge</v-icon>
                <v-icon v-else color="info"> mdi-arrow-right-thin </v-icon>
              </span>
            </template>
            <span v-if="item.merged_stash"
              >Multiple stashed Refuel-Actions have been merged with this
              Refuel-Action</span
            >
            <span v-else>Normal single Refuel-Action</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div v-else>
        <lineChart
          v-if="this.$vuetify.breakpoint.width >= 524"
          class="pb-5"
          height="500px"
          :series="refuelActionChart.series"
          :title="refuelActionChart.title"
          group="refuelAction"
          :xAxisData="refuelActionChart.xAxisData"
          :legendData="refuelActionChart.legendData"
        />
        <v-container v-else>
          <v-row>
            <v-col class="text-center d-flex justify-center">
              <span
                >Please tilt your device or resize the window to show the Graph
                view of your Refuel-History</span
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col>
            <span class="d-flex justify-center text-subtitle-2">
              {{ noDataMessage }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>
<script>
import { createDate } from "@/plugins/vuetify/helper.js";
import lineChart from "../components/lineChart.vue";
export default {
  name: "refuelHistory",
  props: {
    refuelActionItems: Array,
    selectedCar: Object,
    elevation: Number,
    cardTitle: String,
    type: String,
    noDataMessage: String,
  },
  components: {
    lineChart,
  },
  mounted() {
    this.fillGraph();
  },
  data() {
    return {
      fuelTypes: [
        {
          name: "Super E10",
          value: "e10",
        },
        {
          name: "Super E5",
          value: "e5",
        },
        {
          name: "Diesel",
          value: "diesel",
        },
      ],
      refuelActions: {
        displayType: "graph",
        headers: [
          {
            text: "Timestamp",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Trip",
            align: "center",
            sortable: true,
            value: "trip",
          },
          {
            text: "Consumption",
            align: "center",
            sortable: true,
            value: "trip_consumption",
          },
          {
            text: "Fuel-Type",
            align: "center",
            sortable: true,
            value: "fuel_type",
          },
          {
            text: "Litres",
            align: "center",
            sortable: true,
            value: "litres",
          },
          {
            text: "Price",
            align: "center",
            sortable: true,
            value: "price",
          },
          {
            text: "Price Per Litre",
            align: "center",
            sortable: true,
            value: "price_per_litre",
          },
          {
            text: "Type",
            align: "center",
            sortable: true,
            value: "merged_stash",
          },
          {
            text: "Gas-Station",
            align: "center",
            sortable: true,
            value: "station_id",
          },
        ],
        items: this.refuelActionItems,
      },
      refuelActionChart: {
        series: [
          {
            type: "line",
            data: [],
            name: "Trip (km)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Litres",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Trip Consumption (l/100km)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Car Consumption (l/100km)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Overall-Price (€)",
            emphasis: {
              focus: "series",
            },
          },
          {
            type: "line",
            data: [],
            name: "Price/Litre (€)",
            emphasis: {
              focus: "series",
            },
          },
        ],
        title: "",
        xAxisData: [],
        legendData: [
          "Trip (km)",
          "Litres",
          "Trip Consumption (l/100km)",
          "Car Consumption (l/100km)",
          "Overall-Price (€)",
          "Price/Litre (€)",
        ],
      },
    };
  },
  watch: {
    refuelActionItems: async function () {
      if (this.selectedCar || this.type === "Station") {
        await this.fillGraph();
      }
    },
  },
  methods: {
    createDate: function (epoch) {
      return createDate(epoch);
    },
    fillGraph: async function () {
      if (this.type === "Station") {
        this.refuelActions.headers.pop();
        this.refuelActions.headers.push({
          text: "Car",
          align: "center",
          sortable: true,
          value: "car_id",
        });
      }
      //RESET GRAPH
      this.refuelActionChart.xAxisData = [];
      this.refuelActionChart.series[0].data = [];
      this.refuelActionChart.series[1].data = [];
      this.refuelActionChart.series[2].data = [];
      this.refuelActionChart.series[3].data = [];
      this.refuelActionChart.series[4].data = [];
      this.refuelActionChart.series[5].data = [];
      //FILL GRAPH
      if (
        this.refuelActionItems.length > 0 &&
        this.refuelActionItems[this.refuelActionItems.length - 1].id
      ) {
        for (let i = 0; i < this.refuelActionItems.length; i++) {
          this.refuelActionChart.xAxisData.push(
            this.createDate(this.refuelActionItems[i].created)
          );
          this.refuelActionChart.series[0].data.push(
            this.refuelActionItems[i].trip
          );
          this.refuelActionChart.series[1].data.push(
            this.refuelActionItems[i].litres
          );
          this.refuelActionChart.series[2].data.push(
            this.refuelActionItems[i].trip_consumption.toPrecision(4)
          );
          this.refuelActionChart.series[3].data.push(
            this.refuelActionItems[i].car_consumption.toPrecision(4)
          );
          this.refuelActionChart.series[4].data.push(
            this.refuelActionItems[i].price
          );
          this.refuelActionChart.series[5].data.push(
            this.refuelActionItems[i].price_per_litre.toPrecision(4)
          );
        }
      }
    },
    getFuelName: function (fuelType) {
      if (fuelType) {
        return this.fuelTypes.filter((type) => {
          return type.value === fuelType;
        })[0].name;
      }
      return null;
    },
    handleCarView: function (carId) {
      this.$router.push({
        name: "Tracker",
        query: { carId: carId },
      });
    },
    handleDetailView: function (stationId) {
      let routeData = this.$router.resolve({
        name: "StationDetail",
        params: { id: stationId },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style scoped></style>
