<template>
  <div>
    <v-app-bar class="primary" fixed>
      <v-toolbar-items @click.stop="openDrawer" class="pa-0 hidden-md-and-up">
        <v-app-bar-nav-icon class="pl-0 ml-n4">
          <v-icon color="white">mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar-items>
      <div class="hidden-sm-and-down" style="width: 170px"></div>
      <v-spacer></v-spacer>
      <fuelTrackerName :height="this.$vuetify.breakpoint.mobile ? 40 : 48" />
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down mr-5">
        <toggleDarkMode />
      </div>
      <v-menu
        open-on-click
        offset-y
        :close-on-content-click="false"
        v-model="menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            large
            class="mr-2 hidden-sm-and-down"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon large>mdi-account</v-icon>
          </v-btn>
        </template>

        <authForm
          v-if="!this.$globalState.isLoggedIn"
          v-bind:menu.sync="menu"
        />
        <profileMenuCard v-else v-bind:menu.sync="menu" />
      </v-menu>
      <div class="hidden-md-and-up" style="width: 34px"></div>
    </v-app-bar>

    <!-- SM and down view -->
    <v-navigation-drawer
      class="hidden-md-and-up"
      v-model="drawer"
      width="300"
      :height="this.$vuetify.breakpoint.height"
      color="background"
      fixed
    >
      <v-app-bar class="primary">
        <v-row>
          <v-col class="d-flex justify-start align-center">
            <fuelTrackerName :width="170" class="mb-n1" />
          </v-col>
          <v-col cols="3" class="d-flex justify-end align-center">
            <toggleDarkMode />
          </v-col>
        </v-row>
      </v-app-bar>
      <v-list>
        <div class="ma-0 pa-0" v-for="view in views" :key="view.name">
          <v-list-item
            v-if="!view.group"
            link
            :to="view.link"
            active-class="primary--text font-weight-bold"
          >
            <v-list-item-icon>
              <v-icon>{{ view.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ view.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            :prepend-icon="view.icon"
            active-class="primary--text font-weight-bold"
            v-model="view.active"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ view.name }}</v-list-item-title>
            </template>
            <div v-for="item in view.items" :key="item.name">
              <v-list-item
                link
                :to="item.link"
                active-class="primary--text font-weight-bold"
              >
                <v-list-item-icon class="ml-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="ml-n4">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>
      <v-footer absolute color="primary">
        <v-row
          @click="handleLogout"
          v-if="this.$globalState.isLoggedIn"
          style="cursor: pointer"
          class="pt-1"
        >
          <v-col cols="2" class="my-1">
            <v-icon class="mt-n1 ml-1" color="error">mdi-logout</v-icon>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="error--text font-weight-bold">Logout</span>
          </v-col>
        </v-row>
        <v-row
          @click="$router.push({ path: '/login' })"
          v-else
          style="cursor: pointer"
          class="pt-1"
        >
          <v-col cols="2" class="my-1">
            <v-icon class="mt-n1">mdi-login</v-icon>
          </v-col>
          <v-col class="d-flex align-center">
            <span>Login</span>
          </v-col>
        </v-row>
        <v-row class="my-1">
          <v-col cols="2">
            <v-icon class="mt-n1">mdi-information</v-icon>
          </v-col>
          <v-col class="text-caption text-truncate">
            Fuel-Station Data from
            <a @click="openTankerkoenig" class="secondary--text"
              >Tankerkönig.de</a
            >
          </v-col>
        </v-row>
      </v-footer>
    </v-navigation-drawer>

    <!-- MD and up view -->
    <v-navigation-drawer
      class="hidden-sm-and-down"
      width="300"
      :height="this.$vuetify.breakpoint.height"
      color="background"
      permanent
      fixed
      expand-on-hover
      @mouseenter="openActiveGroup"
    >
      <div
        @mouseenter="onExpandDesktop"
        style="height: 100%"
        @mouseleave="collapseGroups"
      >
        <v-app-bar class="primary">
          <v-row>
            <v-col class="d-flex justify-start" v-if="!showName">
              <fuelTrackerIcon :width="40" class="ml-n2 mb-n1" />
            </v-col>
            <v-col class="d-flex justify-center" v-else>
              <fuelTrackerName :width="210" />
            </v-col>
          </v-row>
        </v-app-bar>
        <v-list>
          <div class="ma-0 pa-0" v-for="view in views" :key="view.name">
            <v-list-item
              v-if="!view.group"
              link
              :to="view.link"
              active-class="primary--text font-weight-bold"
            >
              <v-list-item-icon>
                <v-icon>{{ view.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ view.name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              v-else
              :prepend-icon="view.icon"
              active-class="primary--text font-weight-bold"
              v-model="view.active"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ view.name }}</v-list-item-title>
              </template>
              <div v-for="item in view.items" :key="item.name">
                <v-list-item
                  link
                  :to="item.link"
                  active-class="primary--text font-weight-bold"
                >
                  <v-list-item-icon class="ml-4">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-n4">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
        </v-list>
        <v-footer absolute color="primary">
          <v-row class="my-1">
            <v-col cols="2">
              <v-icon class="mt-n1">mdi-information</v-icon>
            </v-col>
            <v-col class="text-caption text-truncate">
              Fuel-Station Data from
              <a @click="openTankerkoenig" class="secondary--text"
                >Tankerkönig.de</a
              >
            </v-col>
          </v-row>
        </v-footer>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import toggleDarkMode from "./toggleDarkMode.vue";
import authForm from "./authForm.vue";
import fuelTrackerName from "../components/fuelTrackerName.vue";
import fuelTrackerIcon from "../components/fuelTrackerIcon.vue";
import profileMenuCard from "./profileMenuCard.vue";
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "navbar",
  components: {
    toggleDarkMode,
    authForm,
    profileMenuCard,
    fuelTrackerName,
    fuelTrackerIcon,
  },
  data() {
    return {
      drawer: false,
      menu: false,
      showName: false,
      showBugReport: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.menu = this.$globalState.menu;
    }, 500);
  },
  watch: {
    menu: function () {
      this.$globalState.menu = this.menu;
    },
  },
  computed: {
    showMenu: function () {
      return this.$globalState.menu;
    },
    views: function () {
      if (this.$globalState.isLoggedIn) {
        if (this.$globalState.profile.is_dev) {
          return [
            {
              name: "Home",
              link: "/",
              icon: "mdi-home",
              group: false,
            },
            {
              name: "Gas Stations",
              link: "/gasstations",
              icon: "mdi-fuel",
              group: false,
            },
            {
              name: "Favourite Stations",
              link: "/favouritestations",
              icon: "mdi-star",
              group: false,
            },
            {
              name: "Tracker",
              link: "/tracker",
              icon: "mdi-chart-timeline-variant-shimmer",
              group: false,
            },
            {
              name: "Profile",
              link: "/profile",
              icon: "mdi-account",
              group: false,
            },
            {
              name: "Calculator",
              link: "/calculator",
              icon: "mdi-calculator",
              group: false,
            },
            {
              name: "Development Overview",
              link: "/devoverview",
              icon: "mdi-dev-to",
              group: false,
            },
            {
              name: "Report a Bug",
              link: "/reportbug",
              icon: "mdi-bug",
              group: false,
            },
            {
              name: "Imprint",
              link: "/imprint",
              icon: "mdi-information",
              group: false,
            },
            {
              name: "Privacy Policy",
              link: "/privacypolicy",
              icon: "mdi-text-account",
              group: false,
            },
          ];
        } else {
          return [
            {
              name: "Home",
              link: "/",
              icon: "mdi-home",
              group: false,
            },
            {
              name: "Gas Stations",
              link: "/gasstations",
              icon: "mdi-fuel",
              group: false,
            },
            {
              name: "Favourite Stations",
              link: "/favouritestations",
              icon: "mdi-star",
              group: false,
            },
            {
              name: "Tracker",
              link: "/tracker",
              icon: "mdi-chart-timeline-variant-shimmer",
              group: false,
            },
            {
              name: "Profile",
              link: "/profile",
              icon: "mdi-account",
              group: false,
            },
            {
              name: "Calculator",
              link: "/calculator",
              icon: "mdi-calculator",
              group: false,
            },
            {
              name: "Report a Bug",
              link: "/reportbug",
              icon: "mdi-bug",
              group: false,
            },
            {
              name: "Imprint",
              link: "/imprint",
              icon: "mdi-information",
              group: false,
            },
            {
              name: "Privacy Policy",
              link: "/privacypolicy",
              icon: "mdi-text-account",
              group: false,
            },
          ];
        }
      }
      return [
        {
          name: "Home",
          link: "/",
          icon: "mdi-home",
          group: false,
        },
        {
          name: "Gas Stations",
          link: "/gasstations",
          icon: "mdi-fuel",
          group: false,
        },
        {
          name: "Tracker",
          link: "/tracker",
          icon: "mdi-chart-timeline-variant-shimmer",
          group: false,
        },
        {
          name: "Calculator",
          link: "/calculator",
          icon: "mdi-calculator",
          group: false,
        },
        {
          name: "Report a Bug",
          link: "/reportbug",
          icon: "mdi-bug",
          group: false,
        },
        {
          name: "Imprint",
          link: "/imprint",
          icon: "mdi-information",
          group: false,
        },
        {
          name: "Privacy Policy",
          link: "/privacypolicy",
          icon: "mdi-text-account",
          group: false,
        },
      ];
    },
    responsiveMargin: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h6";
        case "sm":
          return "text-h5";
        case "md":
          return "ml-16 text-h5";
        case "lg":
          return "ml-16 text-h5";
        case "xl":
          return "ml-16 text-h5";
        default:
          return "ml-16 text-h5";
      }
    },
  },
  methods: {
    openBugReport: function () {
      this.showBugReport = true;
    },
    openTankerkoenig: function () {
      window.open("https://www.tankerkoenig.de/", "_blank");
    },
    handleLogout: async function () {
      await supabase.auth.signOut();
      this.$globalState.isLoggedIn = false;
      if (this.$route.meta.authRequired) {
        this.$router.push({ name: "GasStations" });
      }
    },
    activeLinkIconColor: function (link) {
      if (this.$route.path === link) {
        return "secondary";
      } else if (this.$vuetify.theme.dark) {
        return "white";
      } else {
        return "black";
      }
    },
    activeLinkTextColor: function (link) {
      if (this.$route.path === link) {
        return "secondary--text";
      } else if (this.$vuetify.theme.dark) {
        return "white--text";
      } else {
        return "black--text";
      }
    },
    openDrawer: function () {
      this.drawer = !this.drawer;
      this.openActiveGroup();
    },
    openActiveGroup: function () {
      let fullPath = this.$route.path + this.$route.hash;
      for (let i = 0; i < this.views.length; i++) {
        if (this.views[i].group) {
          if (fullPath.includes(this.views[i].link)) {
            this.views[i].active = true;
          }
        }
      }
    },
    collapseGroups: function () {
      this.showName = false;
      for (let i = 0; i < this.views.length; i++) {
        if (this.views[i].group) {
          this.views[i].active = false;
        }
      }
    },
    onExpandDesktop: async function () {
      this.showName = true;
      await this.Sleep(20);
      this.openActiveGroup();
    },
    Sleep: function (milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
};
</script>
<style scoped>
.v-list-item--active {
  background: -webkit-linear-gradient(
    left,
    var(--v-secondary-darken2),
    var(--v-secondary-darken1),
    var(--v-secondary-base),
    var(--v-secondary-lighten1),
    var(--v-secondary-lighten2)
  );
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}
</style>
