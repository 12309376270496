var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"primary","elevation":_vm.elevation}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"text-md-h6 font-weight-bold text-subtitle-1"},[(_vm.cardTitle)?_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.cardTitle))]):_vm._e(),(_vm.refuelActions.displayType === 'graph')?_c('span',{staticClass:"secondary--text"},[_vm._v("Graph")]):_c('span',{staticClass:"secondary--text"},[_vm._v("Table")])]),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.refuelActionItems.length > 0},on:{"click":function($event){_vm.refuelActions.displayType = 'table'}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-table ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":!_vm.refuelActionItems.length > 0},on:{"click":function($event){_vm.refuelActions.displayType = 'graph'}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" mdi-chart-line-stacked ")])],1)],1)],1)],1),(_vm.refuelActionItems.length > 0)?_c('div',[(_vm.refuelActions.displayType === 'table')?_c('v-data-table',{staticClass:"primary",attrs:{"headers":_vm.refuelActions.headers,"items":_vm.refuelActionItems},scopedSlots:_vm._u([{key:"item.litres",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm._f("number")(item.litres,2))+" ")])]}},{key:"item.trip",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(item.trip)+" km ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm.createDate(item.created))+" ")])]}},{key:"item.fuel_type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm.getFuelName(item.fuel_type))+" ")])]}},{key:"item.trip_consumption",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm._f("number")(item.trip_consumption,2))+" l/100km ")])]}},{key:"item.price_per_litre",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm._f("number")(item.price_per_litre,2))+" € ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_vm._v(" "+_vm._s(_vm._f("number")(item.price,2))+" € ")])]}},{key:"item.station_id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":item.station_id ? 'secondary' : ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ml-n2"},'span',attrs,false),on),[_c('v-btn',{attrs:{"icon":"","disabled":!item.station_id},on:{"click":function($event){return _vm.handleDetailView(item.station_id)}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-gas-station")])],1)],1)]}}],null,true)},[(item.station_id)?_c('span',[_vm._v("Click to view Gas-Station-Details")]):_c('span',[_vm._v("No Gas-Station given")])])]}},{key:"item.car_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ml-n2"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleCarView(item.car_id)}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-car")])],1)],1)]}},{key:"item.merged_stash",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.merged_stash)?_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-merge")]):_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-arrow-right-thin ")])],1)]}}],null,true)},[(item.merged_stash)?_c('span',[_vm._v("Multiple stashed Refuel-Actions have been merged with this Refuel-Action")]):_c('span',[_vm._v("Normal single Refuel-Action")])])]}}],null,true)}):_c('div',[(this.$vuetify.breakpoint.width >= 524)?_c('lineChart',{staticClass:"pb-5",attrs:{"height":"500px","series":_vm.refuelActionChart.series,"title":_vm.refuelActionChart.title,"group":"refuelAction","xAxisData":_vm.refuelActionChart.xAxisData,"legendData":_vm.refuelActionChart.legendData}}):_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center d-flex justify-center"},[_c('span',[_vm._v("Please tilt your device or resize the window to show the Graph view of your Refuel-History")])])],1)],1)],1)],1):_c('div',[_c('v-container',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"d-flex justify-center text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.noDataMessage)+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }