import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Meta from "vue-meta";
import vuetify from "./plugins/vuetify";
import VueGeolocation from "vue-browser-geolocation";

//Filter
Vue.filter("number", (value, digits) => {
  if (!value && value !== 0) return "";
  return parseFloat(value).toLocaleString(undefined, {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
});

//GEOLOCATION
Vue.use(VueGeolocation);

//Meta
Vue.use(Meta);

Vue.prototype.$applicationName = "Fuel Tracker";

Vue.config.productionTip = false;

Vue.prototype.$dev = Vue.config.devtools;

Vue.prototype.$apiUrl = "https://api.fuel-tracker.com";
Vue.prototype.$siteUrl = "https://fuel-tracker.com";

if (Vue.prototype.$dev) {
  Vue.prototype.$apiUrl = "http://127.0.0.1:8787";
  Vue.prototype.$siteUrl = "http://localhost:8080";
}

const store = Vue.observable({
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift();
  },
  searchParams: {
    radius: 0,
    location: {
      latitude: 0,
      longitude: 0,
    },
  },
  isLoggedIn: false,
  menu: false,
  profile: {},
  loadingLocation: false,
});

Vue.prototype.$globalState = store;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
