<template>
  <div class="mb-3">
    <div v-if="!detailLoading">
      <v-row>
        <v-col>
          <span class="text-overline font-weight-bold">
            {{ station.brand }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-n8">
        <v-col>
          <span
            class="
              text-md-h3 text-sm-h4 text-h5
              secondary--text
              font-weight-bold
            "
          >
            {{ station.name }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-n6">
        <v-col>
          <a
            :href="mapUrl"
            :class="urlClass"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none; color: inherit"
            >{{ station.street }} {{ station.houseNumber }},
            {{ station.postCode }} {{ station.place }}</a
          >
        </v-col>
      </v-row>
      <v-row class="mt-n3">
        <v-col>
          <span>
            <v-tooltip right v-if="station.isOpen" color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="px-2" color="secondary" v-on="on" v-bind="attrs"
                  >mdi-clock-check-outline</v-icon
                >
              </template>
              <span>Currently opened</span>
            </v-tooltip>
            <v-tooltip right v-else color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="px-2" color="error" v-on="on" v-bind="attrs"
                  >mdi-clock-remove-outline</v-icon
                >
              </template>
              <span>Currently closed</span>
            </v-tooltip>
            <v-tooltip right v-if="station.wholeDay" color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="px-2" color="secondary" v-on="on" v-bind="attrs"
                  >mdi-hours-24</v-icon
                >
              </template>
              <span>Open 24/7</span>
            </v-tooltip>
            <v-tooltip
              right
              v-if="$globalState.isLoggedIn && !isFavourite"
              color="grey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="changeFavouriteState"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon class="px-2" color="grey">mdi-star-outline</v-icon>
                </v-btn>
              </template>
              <span>Add to Favourites</span>
            </v-tooltip>
            <v-tooltip
              right
              v-if="$globalState.isLoggedIn && isFavourite"
              color="yellow"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="changeFavouriteState"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon class="px-2" color="yellow">mdi-star</v-icon>
                </v-btn>
              </template>
              <span class="black--text">Remove from Favourites</span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <!-- mobile-view -->
      <div class="hidden-sm-and-up">
        <v-row>
          <v-col>
            <v-card color="primary" height="100%" elevation="10">
              <v-card-title class="pb-0">
                <span
                  class="
                    text-md-h6 text-subtitle-1
                    font-weight-bold
                    secondary--text
                  "
                  >Current Prices</span
                >
                <v-spacer></v-spacer>
                <v-btn icon @click="getPrice">
                  <v-icon :class="rotateClass" color="secondary"
                    >mdi-refresh</v-icon
                  >
                </v-btn>
              </v-card-title>
              <v-data-table
                :headers="priceTable.headersMobile"
                :items="priceTable.itemsMobile"
                disable-pagination
                disable-sort
                disable-filtering
                hide-default-footer
                class="primary"
                :loading="priceTable.loading"
              >
                <template v-slot:[`item.price`]="{ item }">
                  <span> {{ item.price || " - " }} </span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card color="primary" height="100%" elevation="10">
              <v-card-title class="pb-0">
                <span
                  class="
                    text-md-h6 text-subtitle-1
                    font-weight-bold
                    secondary--text
                  "
                  >Opening Times</span
                >
              </v-card-title>
              <v-data-table
                v-if="station.openingTimes.length > 0"
                :headers="openingTimesHeaders"
                :items="station.openingTimes"
                disable-pagination
                disable-sort
                disable-filtering
                hide-default-footer
                class="primary"
                :loading="detailLoading"
              ></v-data-table>

              <div id="outer" v-else>
                <div
                  id="inner_remaining"
                  class="d-flex justify-center align-center text-center"
                >
                  <v-container class="pb-6">
                    <span
                      >There are no Opening Times given by {{ station.name }}!
                    </span>
                  </v-container>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- desktop-view -->
      <v-row class="hidden-xs-only">
        <v-col>
          <v-card color="primary" height="100%" elevation="10">
            <v-card-title class="pb-0">
              <span
                class="
                  text-md-h6 text-subtitle-1
                  font-weight-bold
                  secondary--text
                "
                >Current Prices</span
              >
              <v-spacer></v-spacer>
              <v-btn icon @click="getPrice">
                <v-icon :class="rotateClass" color="secondary"
                  >mdi-refresh</v-icon
                >
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="priceTable.headers"
              :items="priceTable.items"
              disable-pagination
              disable-sort
              disable-filtering
              hide-default-footer
              class="primary"
              :loading="priceTable.loading"
            >
              <template v-slot:[`item.price`]="{ item }">
                <span> {{ item.price || " - " }} </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col>
          <v-card color="primary" height="100%" elevation="10">
            <v-card-title class="pb-0">
              <span
                class="
                  text-md-h6 text-subtitle-1
                  font-weight-bold
                  secondary--text
                "
                >Opening Times</span
              >
            </v-card-title>
            <v-data-table
              v-if="station.openingTimes.length > 0"
              :headers="openingTimesHeaders"
              :items="station.openingTimes"
              disable-pagination
              disable-sort
              disable-filtering
              hide-default-footer
              class="primary"
              :loading="detailLoading"
            ></v-data-table>

            <div id="outer" v-else>
              <div
                id="inner_remaining"
                class="d-flex justify-center align-center text-center"
              >
                <span
                  >There are no Opening Times given by {{ station.name }}!
                </span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="this.$globalState.isLoggedIn">
        <v-col>
          <refuelHistory
            v-bind:refuelActionItems.sync="refuelActionItems"
            :elevation="10"
            type="Station"
            :cardTitle="'Refuel History for ' + station.name + ' - '"
            :noDataMessage="
              'Will be displayed as soon as you have refueled at least one time at ' +
              station.name +
              '!'
            "
          />
        </v-col>
      </v-row>
    </div>
    <div v-else style="height: 88vh" class="center">
      <v-container>
        <v-row>
          <v-col>
            <heading text="Pending Location Grant" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="hidden-sm-and-down" cols="3"></v-col>
          <v-col md="6" class="text-center text-overline">
            Please grant {{ $applicationName }} to locate your Position. We need
            your position to search for gas-stations in your close surroundings.
            Your location won't be submitted to any third party.
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import refuelHistory from "../components/refuelHistory.vue";
import heading from "../components/heading.vue";
export default {
  name: "StationDetail",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.station.name,
      meta: [
        {
          name: "description",
          content:
            "Detailed information about Gas-Station '" +
            this.station.name +
            "' including current prices, opening times, and location",
        },
      ],
    };
  },
  components: {
    refuelHistory,
    heading,
  },
  data() {
    return {
      isFavourite: false,
      detailLoading: true,
      station: {},
      mapUrl: "",
      openingTimesHeaders: [
        {
          text: "Applicable",
          align: "start",
          sortable: false,
          value: "text",
        },
        {
          text: "Start",
          align: "center",
          sortable: true,
          value: "start",
        },
        {
          text: "End",
          align: "center",
          sortable: true,
          value: "end",
        },
      ],
      priceTable: {
        loading: true,
        headers: [
          {
            text: "Type",
            align: "center",
            sortable: true,
            value: "type",
          },
          {
            text: "Price",
            align: "center",
            sortable: true,
            value: "price",
          },
        ],
        headersMobile: [
          {
            text: "Super E5",
            align: "center",
            sortable: true,
            value: "e5",
          },
          {
            text: "Super E10",
            align: "center",
            sortable: true,
            value: "e10",
          },
          {
            text: "Diesel",
            align: "center",
            sortable: true,
            value: "diesel",
          },
        ],
        items: [],
        itemsMobile: [],
      },
      refuelActionItems: [],
    };
  },
  async mounted() {
    await this.checkFavourite();
    await this.getStationDetail();
    await this.getRefuelActionsForStation();
  },
  computed: {
    rotateClass: function () {
      if (this.priceTable.loading) {
        return "rotate";
      }
      return "";
    },
    urlClass: function () {
      if (this.$vuetify.theme.dark) {
        return "text-subtitle-2 white--text";
      }
      return "text-subtitle-2 black--text";
    },
  },
  methods: {
    checkFavourite: async function () {
      let response = await supabase
        .from("favouritestations")
        .select()
        .match({ station_id: this.$route.params.id });
      if (response.data.length > 0) {
        this.isFavourite = true;
      }
    },
    changeFavouriteState: async function () {
      if (this.isFavourite) {
        //remove
        let response = await supabase
          .from("favouritestations")
          .delete()
          .match({ station_id: this.$route.params.id });
        if (response.data.length > 0) {
          this.isFavourite = false;
        }
      } else {
        //add
        let response = await supabase.from("favouritestations").insert([
          {
            user_id: supabase.auth.user().id,
            station_id: this.$route.params.id,
            created: Date.now(),
          },
        ]);
        if (response.data.length > 0) {
          this.isFavourite = true;
        }
      }
    },
    getRefuelActionsForStation: async function () {
      let response = await supabase
        .from("refuelactions")
        .select()
        .match({ station_id: this.$route.params.id });
      if (response.data.length > 0) {
        this.refuelActionItems = response.data;
      } else {
        this.refuelActionItems = [];
      }
    },
    getStationDetail: async function () {
      this.detailLoading = true;
      if (this.$route.params.id) {
        let payload = {
          stationId: this.$route.params.id,
        };
        let response = await fetch(this.$apiUrl + "/station/detail", {
          method: "POST",
          body: JSON.stringify(payload),
        });

        let responseData = await response.json();

        this.station = responseData.details;
        this.mapUrl =
          "http://www.google.com/maps/place/" +
          responseData.details.lat +
          "," +
          responseData.details.lng +
          "";
        this.detailLoading = false;
        await this.getPrice();
      }
    },
    getPrice: async function () {
      this.priceTable.loading = true;
      if (this.$route.params.id) {
        let payload = {
          stationIds: [this.$route.params.id],
        };
        let response = await fetch(this.$apiUrl + "/station/prices", {
          method: "POST",
          body: JSON.stringify(payload),
        });

        let responseData = await response.json();

        let prices = responseData.prices[this.$route.params.id];
        this.priceTable.items = [
          { type: "Super E5", price: prices.e5 },
          { type: "Super E10", price: prices.e10 },
          { type: "Diesel", price: prices.diesel },
        ];

        this.priceTable.itemsMobile = [
          { e5: prices.e5, e10: prices.e10, diesel: prices.diesel },
        ];

        this.priceTable.loading = false;
      }
    },
  },
};
</script>
<style scoped>
#outer {
  display: flex;
  flex-flow: column;
  height: 80%;
}

#inner_remaining {
  flex-grow: 1;
}
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.center {
  display: grid;
  place-items: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
