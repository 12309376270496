<template>
  <div>
    <heading text="Development Overview" />
    <v-row>
      <v-col class="hidden-md-and-down" cols="1"></v-col>
      <v-col lg="10">
        <v-card color="primary">
          <v-card-title class="pb-0">
            <span
              class="
                text-md-h6 text-subtitle-1
                font-weight-bold
                secondary--text
              "
              >Submitted Bugs</span
            >
            <v-spacer></v-spacer>
            <div class="pr-5">
              <v-text-field
                style="width: 350px"
                label="Search"
                append-icon="mdi-magnify"
                single-line
                hide-details
                color="secondary"
                v-model="bugTable.search"
              >
              </v-text-field>
            </div>

            <v-btn icon @click="getBugs">
              <v-icon :class="rotateClass" color="secondary"
                >mdi-refresh</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-data-table
            :search="bugTable.search"
            :headers="bugTable.headers"
            :items="bugTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
            :loading="bugTable.loading"
            show-expand
            single-expand
          >
            <template v-slot:[`item.view`]="{ item }">
              <a
                v-if="item.view != 'Detail view of a Station'"
                @click="pushView(item.view)"
                class="secondary--text"
              >
                {{ item.view }}
              </a>
              <span v-else> {{ item.view }} </span>
            </template>
            <template v-slot:[`item.severity`]="{ item }">
              <span v-if="item.severity === 'Low'" class="success--text"
                >Low</span
              >
              <span v-else-if="item.severity === 'Medium'" class="orange--text"
                >Medium</span
              >
              <span v-else-if="item.severity === 'High'" class="error--text"
                >High</span
              >
              <span
                v-else-if="item.severity === 'Critical'"
                class="error--text font-weight-bold text-decoration-underline"
                >Critical</span
              >
            </template>
            <template v-slot:[`item.is_open`]="{ item }">
              <v-icon v-if="item.is_open" color="error"
                >mdi-alert-circle-outline</v-icon
              >
              <v-icon v-else color="success">mdi-check-circle-outline</v-icon>
            </template>
            <template v-slot:[`item.browser`]="{ item }">
              <v-icon v-if="item.browser === 'Chrome'"
                >mdi-google-chrome</v-icon
              >
              <v-icon v-if="item.browser === 'Safari'">mdi-apple-safari</v-icon>
              <v-icon v-if="item.browser === 'Opera'">mdi-opera</v-icon>
              <v-icon v-if="item.browser === 'Firefox'">mdi-firefox</v-icon>
              <v-icon v-if="item.browser === 'Microsoft Edge'"
                >mdi-microsoft-edge</v-icon
              >
              <v-icon v-if="item.browser === 'Internet Explorer'"
                >mdi-microsoft-internet-explorer</v-icon
              >
            </template>
            <template v-slot:[`item.platform`]="{ item }">
              <v-icon v-if="item.platform === 'Windows'"
                >mdi-microsoft-windows</v-icon
              >
              <v-icon v-if="item.platform === 'MacOS'">mdi-apple</v-icon>
              <v-icon v-if="item.platform === 'Linux'">mdi-linux</v-icon>
              <v-icon v-if="item.platform === 'Android'">mdi-android</v-icon>
              <v-icon v-if="item.platform === 'iOS'">mdi-apple-ios</v-icon>
              <v-icon v-if="item.platform === 'Other'">mdi-help</v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col class="mt-5">
                    <span class="font-weight-bold text-h6 secondary--text"
                      >Steps to Reproduce</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mb-3">
                    {{ item.reproduce_steps }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="font-weight-bold text-h6 secondary--text"
                      >Expected Behavior</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mb-3">
                    {{ item.expected_behavior }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="font-weight-bold text-h6 secondary--text"
                      >Actual Behavior</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mb-3">
                    {{ item.actual_behavior }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="font-weight-bold text-h6 secondary--text"
                      >Additional Information</span
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0 mb-3" v-if="item.additional_information">
                    {{ item.additional_information }}
                  </v-col>
                  <v-col class="pt-0 mb-3" v-else>-</v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                v-if="!item.is_open"
                color="error"
                small
                class="mr-2"
                @click="changeBugState(item)"
                >mdi-alert-circle-outline</v-icon
              >
              <v-icon
                v-else
                color="success"
                small
                @click="changeBugState(item)"
                class="mr-2"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon small @click="updateGhIssue(item)">mdi-github</v-icon>
              <v-icon color="error" small @click="deleteBug(item)" class="ml-2"
                >mdi-delete</v-icon
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay v-model="ghIssue.overlay">
      <v-card
        color="background"
        outlined
        class="overlay"
        :light="!$vuetify.theme.dark"
      >
        <v-container>
          <v-row>
            <v-col>
              <span class="text-h6 font-weight-bold secondary--text"
                >GitHub Issue Link</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span
                >Enter here your Issue Link from Github, to connect this Bug to
                an Github Issue</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Enter Issue Link"
                color="secondary"
                v-model="ghIssue.link"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn
                @click="ghIssue.overlay = !ghIssue.overlay"
                color="background"
                class="mr-3 cancel text--text"
                text
                >Cancel</v-btn
              >
              <v-btn
                @click="updateGhIssue"
                color="success"
                text
                class="save"
                :disabled="!ghIssue.link"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
  </div>
</template>
<script>
import { createDate } from "@/plugins/vuetify/helper.js";
import { supabase } from "@/plugins/supabase/supabase.js";
import heading from "../components/heading.vue";
export default {
  name: "DevOverview",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Development",
      meta: [
        {
          name: "description",
          content:
            "Display Development Information about " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    heading,
  },
  data() {
    return {
      ghIssue: {
        bugId: null,
        overlay: false,
        link: "",
      },
      bugTable: {
        search: "",
        loading: false,
        items: [],
        headers: [
          {
            text: "Status",
            align: "center",
            sortable: true,
            value: "is_open",
          },
          {
            text: "Description",
            align: "center",
            sortable: true,
            value: "description",
          },
          {
            text: "View",
            align: "center",
            sortable: true,
            value: "view",
          },
          {
            text: "Severity",
            align: "center",
            sortable: true,
            value: "severity",
          },
          {
            text: "Platform",
            align: "center",
            sortable: true,
            value: "platform",
          },
          {
            text: "Browser",
            align: "center",
            sortable: true,
            value: "browser",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            sortable: false,
          },
        ],
      },
    };
  },
  async mounted() {
    await this.getBugs();
  },
  computed: {
    rotateClass: function () {
      if (this.bugTable.loading) {
        return "rotate";
      }
      return "";
    },
  },
  methods: {
    getBugs: async function () {
      this.bugTable.items = [];
      let response = await supabase.from("bugs");

      if (response.data.length > 0) {
        let bugs = [];

        for (let i = 0; i < response.data.length; i++) {
          let bug = response.data[i];
          bug.created = createDate(
            new Date(response.data[i].created).valueOf()
          );
          bug.updated = createDate(
            new Date(response.data[i].updated).valueOf()
          );
          bugs.push(bug);
        }
        this.bugTable.items = bugs;
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Fetching Bugs",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else if (response.data.length != 0) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Failed fetching Bugs",
          message: "",
        };
        this.$globalState.addNotification(notify);
      }
    },
    changeBugState: async function (item) {
      let response = await supabase
        .from("bugs")
        .update({
          is_open: !item.is_open,
          updated: Date.now(),
        })
        .match({ id: item.id });

      if (response.data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Bug State Change",
          message:
            "Successfully changed State for Bug '" + item.description + "'!",
        };
        this.$globalState.addNotification(notify);
        this.getBugs();
        this.ghIssue.overlay = false;
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Bug State Change",
          message: "Failed changing State for Bug '" + item.description + "'!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    deleteBug: async function (item) {
      let response = await supabase
        .from("bugs")
        .delete()
        .match({ id: item.id });

      if (response.data.length > 0) {
        let notify = {
          duration: 4000,
          type: "success",
          headline:
            "Successfully deleted '" + item.description + "' from Bug-List",
          message: "",
        };
        this.$globalState.addNotification(notify);
        this.getBugs();
      } else if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Bug from List",
          message: response.error,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Bug from List",
          message: "Failed deleting Bug! Try again later!",
        };
        this.$globalState.addNotification(notify);
      }
    },
    updateGhIssue: async function (item) {
      if (item.id) {
        if (item.github_issue_link === null) {
          this.ghIssue.bugId = item.id;
          this.ghIssue.overlay = true;
        } else {
          window.open(item.github_issue_link, "_blank");
        }
      } else {
        let response = await supabase
          .from("bugs")
          .update({
            github_issue_link: this.ghIssue.link,
            updated: Date.now(),
          })
          .match({ id: this.ghIssue.bugId });

        if (response.data.length > 0) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "Adding Issue Link",
            message: "Successfully added Issue Link!",
          };
          this.$globalState.addNotification(notify);
          this.getBugs();
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "Adding Issue Link",
            message: "Failed adding Issue Link!",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    pushView: function (title) {
      let routeName = this.$router.options.routes.filter((route) => {
        return route.title === title;
      })[0].name;
      this.$router.push({ name: routeName });
    },
  },
};
</script>
<style scoped>
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.cancel {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-primary-base);
}
.save {
  outline: solid;
  outline-width: 2px;
  outline-color: var(--v-success-base);
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
